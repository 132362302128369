import { useState, useEffect } from "react";
import avatar from "../../assets/images/avatar2.png";
import Dialog from "../Dialog";
import ReportDialog from "../dialogs/ReportDialog";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";
import RatingStars from "../ui/buttons/RatingStars";
import Swal from 'sweetalert2';




function ProposalCardUnregistred({
  showContactButton = true,
  CustomProfessionalButton = null,
  contactor = false,
}) {
  const [reportDialog, setReportDialog] = useState(0);
  const [confirmHireDialog, setConfirmHireDialog] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const projectId = localStorage.getItem('project_id');

  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const response = await fetch(`https://eprof-production-6g7zxvibga-uc.a.run.app/job_applicants/${projectId}/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();

        if (response.ok) {
          setApplicants(data.applicants);
        } else {

        }

      } catch (error) {
        console.error('Error:', error);
        Swal.fire({
          title: 'Error',
          text: 'Ocurrió un error al obtener los aplicantes. Por favor, intenta nuevamente.',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    };

    if (projectId) {
      fetchApplicants();
    }
  }, [projectId]);

  const hireApplicant = async () => {
    try {
      const response = await fetch('https://eprof-production-6g7zxvibga-uc.a.run.app/select_applicant/', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          job_id: projectId,
          user_id: applicants[0].user_id
        }),
      });

      const data = await response.json();

      if (response.ok) {
        Swal.fire({
          title: 'Éxito',
          text: 'El abogado ha sido asignado exitosamente.Por favor, espere a que se ponga en contacto con usted.',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        });
      } else {
        console.error('Error al contratar al aplicante:', data.message || 'Ocurrió un error');
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        title: 'Error',
        text: 'Ocurrió un error al contratar al aplicante. Por favor, intenta nuevamente.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleConfirmHire = () => {
    hireApplicant(); // Llama a la función de contratación
    setConfirmHireDialog(false); // Cierra el modal
  };

  const handleCancelHire = () => {
    setConfirmHireDialog(false); // Cierra el modal sin hacer nada
  };

  return (
    <>
      <Dialog
        setDialog={setReportDialog}
        showDialog={reportDialog}
        main={<ReportDialog setDialog={setReportDialog} />}
      />
      {/* Modal de Confirmación Codigo generico momentanea mente */}
      {confirmHireDialog && (
        <div className="modal">
          <div className="modal-content">
            <h2>Confirmar Contratación</h2>
            <p>¿Estás seguro de que deseas contratar a este abogado?</p>
            <div className="flex justify-end">
              <Button onClick={handleCancelHire} color="red">Cancelar</Button>
              <Button onClick={handleConfirmHire} color="green">Confirmar</Button>
            </div>
          </div>
        </div>
      )}
      {/* Eliminar despues asta aqui */}
      {applicants.map(applicant => (
        <div key={applicant.user_id} className="flex flex-col justify-start items-start self-stretch relative gap-4 p-[30px] bg-white border border-[#cdcdcd]">
          <div className="flex relative flex-col gap-5 justify-start self-stretch lg:items-start lg:flex-row">
            <div className="flex flex-grow flex-shrink gap-4 justify-start items-center cursor-pointer">
              <img
                src={avatar}
                alt={`${applicant.first_name} ${applicant.last_name}`}
                className="w-full max-w-[84px] h-[64px] lg:h-[84px] rounded-[999px] object-contain"
              />
              <div className="flex flex-col justify-center items-start flex-grow relative gap-[5px]">
                <div className="flex justify-start items-center relative gap-[5px]">
                  <p className="text-sm lg:text-base font-bold text-left text-[#373737]">
                    {applicant.first_name} {applicant.last_name}
                  </p>
                  <svg
                    width={24}
                    height={25}
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative w-6 h-6"
                    preserveAspectRatio="none"
                  >
                    <path d="M21.9997 5.5H2V18.8333H21.9997V5.5Z" fill="white" />
                    <path
                      d="M8.66643 5.5H2V18.8333H8.66643V5.5Z"
                      fill="#4997D0"
                    />
                    <path
                      d="M22.0004 5.5H15.334V18.8333H22.0004V5.5Z"
                      fill="#4997D0"
                    />
                  </svg>
                </div>
                <p className="underline text-sm lg:text-base text-left text-[#d80027]">
                  @{applicant.username}
                </p>

                <p className="self-stretch overflow-hidden text-sm lg:text-base font-bold text-left text-[#373737]">
                  {applicant.nationality}
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-center items-start flex-grow gap-[5px]">
              <div className="flex justify-start items-center h-[22px] relative gap-4">
                <RatingStars rating={3.5} />

                <p className="text-sm lg:text-base text-left text-[#373737]">
                  3,5
                </p>
              </div>
              <div className="flex gap-4 justify-start items-center">
                <div className="flex justify-start items-center relative gap-[2.3480663299560547px]">
                  <svg
                    width={24}
                    height={25}
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative w-6 h-6"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path
                      d="M18 4.5H6C4.875 4.5 4 5.40625 4 6.5V15.5C4 16.625 4.875 17.5 6 17.5H9V20.125C9 20.375 9.15625 20.5 9.375 20.5C9.4375 20.5 9.5 20.5 9.59375 20.4375L13.5 17.5H18C19.0938 17.5 20 16.625 20 15.5V6.5C20 5.40625 19.0938 4.5 18 4.5ZM18.5 15.5C18.5 15.7812 18.25 16 18 16H13L12.5938 16.3125L10.5 17.875V16H6C5.71875 16 5.5 15.7812 5.5 15.5V6.5C5.5 6.25 5.71875 6 6 6H18C18.25 6 18.5 6.25 18.5 6.5V15.5Z"
                      fill="#333333"
                    />
                  </svg>
                  <p className="text-sm lg:text-base text-left text-[#7b7b7b]">
                    0
                  </p>
                </div>
                <div className="flex justify-start items-center relative gap-[2.3480663299560547px]">
                  <svg
                    width={25}
                    height={25}
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative w-6 h-6"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path
                      d="M18.754 13.5H11.3477V6.09375C11.3477 5.8125 11.0977 5.59375 10.8477 5.59375C10.8165 5.59375 10.7852 5.59375 10.7852 5.59375C7.06647 6.125 4.22272 9.375 4.34772 13.2812C4.47272 17.1562 7.69147 20.375 11.5665 20.5C11.6602 20.5 11.754 20.5 11.8477 20.5C15.629 20.5 18.7227 17.7188 19.254 14.0625C19.2852 13.7812 19.0352 13.5 18.754 13.5ZM11.8477 19C11.754 19 11.6915 19 11.629 19C8.53522 18.9062 5.94147 16.3125 5.84772 13.2188C5.75397 10.5625 7.41022 8.21875 9.84772 7.375V15H17.4727C16.6602 17.375 14.4102 19 11.8477 19ZM13.3477 4.53125C13.3477 4.53125 13.3477 4.5 13.3165 4.5C13.0665 4.5 12.8477 4.75 12.8477 5.03125V12H19.8165C20.0977 12 20.3477 11.7812 20.3165 11.5C20.0665 7.75 17.0977 4.78125 13.3477 4.53125Z"
                      fill="#333333"
                    />
                  </svg>
                  <p className="text-sm lg:text-base text-left text-[#7b7b7b]">
                    100%
                  </p>
                </div>
              </div>
            </div>
            <div className="flex relative flex-col justify-start items-start">
              <p className="self-stretch w-28 text-2xl font-bold text-right text-[#373737]">
                GTQ {applicant.offer_amount}
              </p>
              <p className="self-stretch w-28 text-sm text-right text-[#7b7b7b]">
                en {applicant.delivery_time} días
              </p>
            </div>
          </div>
          <div>
            <p className="self-stretch w-full max-w-[1116px] text-sm lg:text-base text-left">
              <span className="self-stretch w-full max-w-[1116px] text-sm lg:text-base text-left text-[#7b7b7b]">
                {applicant.proposal_description}
              </span>
              <span className="self-stretch underline cursor-pointer hover:underline w-full max-w-[1116px] text-sm lg:text-base text-left text-[#d80027]">
                más...
              </span>
            </p>
          </div>
          <div className="flex flex-col gap-5 justify-end self-stretch lg:flex-row lg:items-center">
            <button
              onClick={() => setReportDialog(1)}
              className="flex text-sm lg:text-base text-left underline text-[#d80027] justify-center items-center h-11 relative gap-2.5 py-2.5 rounded-[20px]"
            >
              Denunciar oferta
            </button>
            <div className="flex flex-col flex-grow gap-5 justify-end lg:items-center lg:flex-row">
              {contactor && (
                <Button
                  color={COLOR.WHITE}
                  size={SIZE.MEDIUM}
                  addClass={"h-11"}
                  iconName={"arrow"}
                >
                  Contactar
                </Button>
              )}
              {CustomProfessionalButton ? (
                CustomProfessionalButton
              ) : (
                <>
                  {showContactButton ? (
                    <Button
                      color={COLOR.WHITE}
                      addClass={"h-11"}
                      iconName={"arrow"}
                      text="Contactar"
                    />
                  ) : null}
                  <Button addClass={"h-11"} onClick={hireApplicant}>Contratar</Button>
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default ProposalCardUnregistred;
