import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function useToggleHeaderFooter() {
    const [toggleValue, setToggle] = useState(1);
    const location = useLocation();

    const checkUrl = useCallback(() => {
        setToggle(
            !["register", "login", "sign"].some((v) =>
                location.pathname.includes(v)
            )
        );
    }, [location.pathname]);
    useEffect(() => {
        checkUrl();
        // return () => {};
    }, [checkUrl]);

    return {
        toggleValue
    };
}

export default useToggleHeaderFooter;
