// import { Button, COLOR, SIZE } from "../../ui/buttons/Button";
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

function SearchingProfessional({ goToNext }) {
    const [applicants, setApplicants] = useState([]);

    useEffect(() => {
        const fetchApplicants = async () => {
            const projectId = localStorage.getItem("project_id");
            if (projectId) {
                try {
                    const response = await fetch(
                        `https://eprof-production-6g7zxvibga-uc.a.run.app/job_applicants/${projectId}/`,
                        {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                            }
                        }
                    );
                    const data = await response.json();

                    if (response.ok) {
                        setApplicants(data.applicants);
                    } else {
                        throw new Error("Error al obtener los aplicantes");
                    }
                } catch (error) {
                    console.error("Error:", error);
                    Swal.fire({
                        title: "Error",
                        text: "Ocurrió un error al obtener los aplicantes. Por favor, intenta nuevamente.",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    });
                }
            }
        };

        fetchApplicants();
    }, []);

    // Este useEffect detecta cambios en applicants
    useEffect(() => {
        if (applicants.length > 0) {
            goToNext(); // Avanza al siguiente paso si hay candidatos
        }
    }, [applicants, goToNext]);


    

    return (
        <div className="flex flex-col justify-start py-20   gap-[30px]">
            <div className="flex relative flex-col  gap-6 justify-start items-center lg:flex-row">
                <svg
                    width={216}
                    height={214}
                    viewBox="0 0 216 214"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="flex-grow-0 flex-shrink-0 max-w-[216px] h-[213.72px]"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M193.854 34.8049L181 25.8161L184.134 21.3325L192.754 27.3558L203.922 13.1895L208.221 16.5779L193.854 34.8049Z"
                        fill="#E0E0E0"
                    />
                    <path
                        d="M168.414 31.3326C169.642 30.7905 170.683 29.9 171.41 28.7716C172.137 27.6431 172.516 26.3262 172.502 24.9841C172.502 21.189 169.65 18.1042 166.093 18.088C162.537 18.0717 159.642 21.1294 159.626 24.9244C159.601 26.2784 159.977 27.6095 160.707 28.75C161.438 29.8904 162.489 30.789 163.73 31.3326C153.825 32.8235 154.275 43.5797 154.275 43.5797L177.701 43.6827C177.701 43.6827 178.238 32.8181 168.414 31.3326Z"
                        fill="#EBEBEB"
                    />
                    <path
                        d="M150.304 23.9822C150.934 23.7035 151.47 23.2476 151.845 22.67C152.221 22.0924 152.42 21.4181 152.419 20.7293C152.447 20.2763 152.382 19.8225 152.228 19.3954C152.075 18.9684 151.836 18.5772 151.526 18.2458C151.216 17.9144 150.841 17.6498 150.425 17.4682C150.01 17.2865 149.561 17.1917 149.107 17.1895C148.653 17.1873 148.204 17.2777 147.786 17.4553C147.369 17.6328 146.992 17.8938 146.679 18.2221C146.365 18.5505 146.122 18.9393 145.965 19.3648C145.807 19.7903 145.738 20.2436 145.761 20.6968C145.748 21.3966 145.942 22.0848 146.319 22.6744C146.696 23.264 147.24 23.7286 147.881 24.0093C142.763 24.7845 143.002 30.347 143.002 30.347L155.108 30.4012C155.108 30.4012 155.379 24.752 150.304 23.9822Z"
                        fill="#EBEBEB"
                    />
                    <path
                        d="M0 174.788C18.7041 172.619 32.1276 183.419 32.1276 183.419C17.2077 189.003 0 174.788 0 174.788Z"
                        fill="#E0E0E0"
                    />
                    <path
                        d="M54.6767 179.189C53.5924 190.694 45.1133 197.921 45.1133 197.921C43.5681 188.417 54.6767 179.189 54.6767 179.189Z"
                        fill="#E0E0E0"
                    />
                    <path
                        d="M43.2745 202.806C29.466 204.183 18.9863 195.905 18.9863 195.905C29.8293 191.931 43.2745 202.806 43.2745 202.806Z"
                        fill="#E0E0E0"
                    />
                    <path
                        d="M28.1142 171.08C28.3365 170.949 33.4815 179.575 39.6023 190.337C45.7232 201.098 50.5103 209.93 50.2826 210.06C50.0549 210.19 44.9154 201.565 38.7945 190.797C32.6737 180.03 27.8865 171.204 28.1142 171.08Z"
                        fill="#E0E0E0"
                    />
                    <path
                        d="M10.0156 147.301C10.0156 147.301 29.5329 156.322 30.2811 175.492C30.2811 175.498 13.545 168.027 10.0156 147.301Z"
                        fill="#E0E0E0"
                    />
                    <path
                        d="M40.1584 152.375C40.1584 152.375 50.4266 171.236 39.6162 187.072C39.6216 187.094 30.6383 171.122 40.1584 152.375Z"
                        fill="#E0E0E0"
                    />
                    <path
                        d="M156.385 146.848C166.334 165.573 161.324 183.974 161.324 183.974C149.581 170.61 156.385 146.848 156.385 146.848Z"
                        fill="#E0E0E0"
                    />
                    <path
                        d="M174.92 205.908C163.128 209.4 152.464 203.42 152.464 203.42C161.154 197.977 174.92 205.908 174.92 205.908Z"
                        fill="#E0E0E0"
                    />
                    <path
                        d="M155.567 195.971C148.183 188.381 147.755 178.416 147.755 178.416C155.193 183.024 155.567 195.971 155.567 195.971Z"
                        fill="#E0E0E0"
                    />
                    <path
                        d="M171.798 174.821C172.02 175.006 165.677 183.859 157.631 194.599C149.586 205.339 142.89 213.905 142.673 213.72C142.457 213.536 148.794 204.683 156.818 193.937C164.842 183.192 171.581 174.637 171.798 174.821Z"
                        fill="#E0E0E0"
                    />
                    <path
                        d="M187.654 146.305C187.654 146.305 186.917 170.349 168.354 178.871C168.354 178.866 168.711 158.33 187.654 146.305Z"
                        fill="#E0E0E0"
                    />
                    <path
                        d="M195.261 179.918C195.261 179.918 180.975 198.258 160.829 193.314C160.829 193.314 172.811 177.478 195.261 179.918Z"
                        fill="#E0E0E0"
                    />
                    <path
                        d="M215.999 211.793C215.999 211.875 168.152 211.934 109.143 211.934C50.1349 211.934 2.26074 211.875 2.26074 211.793C2.26074 211.712 50.0988 211.652 109.143 211.652C168.188 211.652 215.999 211.717 215.999 211.793Z"
                        fill="#263238"
                    />
                    <path
                        d="M130.543 212.147C130.543 212.147 133.368 207.138 129.345 186.406C127.036 174.511 116.811 159.174 116.811 159.174C116.811 159.174 67.8602 160.388 67.8168 161.169C67.7734 161.95 52.8373 177.124 50.6687 192.906C48.5001 208.688 48.923 212.147 48.923 212.147H130.543Z"
                        fill="#263238"
                    />
                    <path
                        d="M91.901 193.78C91.9769 193.78 92.3455 187.535 92.725 179.831C93.1045 172.127 93.3431 165.871 93.2672 165.865C93.1913 165.86 92.8172 172.105 92.4431 179.815C92.069 187.524 91.8197 193.775 91.901 193.78Z"
                        fill="#455A64"
                    />
                    <path
                        d="M87.497 167.827C88.3861 167.784 89.259 168.689 89.5084 169.454C89.6977 170.02 89.6849 170.634 89.4721 171.192C89.2593 171.75 88.8596 172.216 88.3412 172.513C87.8229 172.809 87.2178 172.916 86.6292 172.816C86.0406 172.716 85.5048 172.415 85.1132 171.965C84.7215 171.514 84.4983 170.942 84.4814 170.345C84.4645 169.748 84.6551 169.164 85.0207 168.692C85.3862 168.22 85.9042 167.889 86.4862 167.756C87.0682 167.623 87.6784 167.696 88.2126 167.963"
                        fill="#455A64"
                    />
                    <path
                        d="M104.195 73.7476L100.98 65.6154L81.7992 64.6016L73.9652 65.6154L73.1031 67.8653L67.7847 161.949C67.7847 161.949 86.3641 165.696 95.6565 164.395C104.949 163.093 115.862 158.854 115.862 158.854V105.349L104.195 73.7476Z"
                        fill="#E0E0E0"
                    />
                    <path
                        d="M75.3272 77.5711C75.3613 77.6338 75.4052 77.6905 75.4573 77.7392L75.8748 78.2054C76.2434 78.6121 76.7964 79.1922 77.5012 79.9078C79.4788 81.8681 81.5774 83.7023 83.7847 85.3997L83.8714 85.4756L83.9528 85.3889C86.0259 83.1144 87.9492 80.7076 89.7104 78.1838L90.2796 77.3543H90.0465L94.6602 83.5619C94.8394 83.8098 95.055 84.0291 95.2999 84.2124C95.5537 84.4054 95.8723 84.4928 96.189 84.4564C96.4854 84.3945 96.7547 84.2408 96.9589 84.0173C97.1505 83.8211 97.3314 83.6147 97.501 83.3992L99.3172 81.1168L101.93 77.8205L102.63 76.9151C102.715 76.8138 102.791 76.7049 102.857 76.5898C102.756 76.6762 102.665 76.7745 102.586 76.8826C102.402 77.0995 102.153 77.3868 101.849 77.75L99.1654 81.0029L97.3221 83.2583C96.9914 83.654 96.6607 84.1365 96.1619 84.2179C95.6631 84.2992 95.2132 83.8655 94.8716 83.4101L90.285 77.1916L90.1712 77.0344L90.0573 77.1971L89.4881 78.0211C87.7332 80.5345 85.8248 82.9372 83.7739 85.2154H83.9473C81.7401 83.5262 79.628 81.7162 77.6205 79.7939C76.8832 79.0946 76.3193 78.5307 75.9344 78.1675C75.7501 77.9886 75.6037 77.8476 75.4898 77.7284C75.4429 77.6689 75.3882 77.616 75.3272 77.5711Z"
                        fill="#263238"
                    />
                    <g opacity="0.3">
                        <path
                            d="M97.2422 83.5391C97.2422 83.5391 94.8134 86.5318 94.1303 85.7836C93.4471 85.0354 89.8419 79.4513 89.4082 79.2182C88.9744 78.9851 84.6264 86.8083 82.9024 86.2661C81.1784 85.724 75.1009 79.6302 75.1009 79.6302L74.9816 77.2285L83.8566 85.3065L90.1563 77.2827L95.4585 84.1788C95.4585 84.1788 96.597 85.0517 97.2422 83.5391Z"
                            fill="black"
                        />
                    </g>
                    <path
                        d="M112.489 19.6641C112.489 19.6641 117.504 38.4224 117.938 51.6995L118.084 56.118C118.084 56.118 107.008 65.3345 97.7264 65.8767L89.9411 46.7389L112.489 19.6641Z"
                        fill="#263238"
                    />
                    <path
                        d="M63.3184 31.1681C65.4328 22.0166 66.246 11.3689 73.8578 5.40526C79.5015 0.975921 85.9368 -0.260174 93.104 0.0434283C96.6334 0.189808 100.618 1.81625 103.698 3.55654C107.91 5.94199 109.694 10.7834 111.732 15.1802C115.804 23.9901 115.289 35.9173 112.529 39.2298L75.7227 58.6658C68.3712 54.8437 63.7684 53.3202 62.5919 43.5236C62.1168 39.3941 62.3626 35.2135 63.3184 31.1681Z"
                        fill="#263238"
                    />
                    <path
                        d="M74.2422 12.6756L97.4028 5.83909C104.868 4.7548 110.214 13.7815 110.984 21.2849C111.835 29.6177 112.55 39.8263 111.808 46.0989C110.311 58.7093 100.764 60.6827 100.764 60.6827L100.835 65.7463L90.2844 76.9579L74.1609 66.1801L70.4743 17.3001C70.4086 16.1973 70.7593 15.11 71.4571 14.2536C72.1549 13.3971 73.1489 12.834 74.2422 12.6756Z"
                        fill="#FFBF9D"
                    />
                    <path
                        d="M106.732 32.6964C106.748 33.1137 106.602 33.5209 106.326 33.8338C106.049 34.1467 105.663 34.3412 105.247 34.3771C105.043 34.4019 104.836 34.3856 104.639 34.329C104.442 34.2725 104.258 34.177 104.098 34.0481C103.938 33.9192 103.806 33.7595 103.709 33.5785C103.612 33.3976 103.552 33.199 103.534 32.9946C103.518 32.5774 103.664 32.1701 103.94 31.8573C104.217 31.5444 104.603 31.3499 105.019 31.314C105.223 31.2892 105.43 31.3055 105.627 31.362C105.824 31.4186 106.008 31.5141 106.168 31.643C106.328 31.7719 106.46 31.9316 106.557 32.1125C106.654 32.2935 106.713 32.492 106.732 32.6964Z"
                        fill="#263238"
                    />
                    <path
                        d="M109.452 28.8421C109.262 29.0589 108.01 28.2078 106.275 28.2999C104.54 28.3921 103.315 29.2867 103.119 29.0806C103.022 28.9939 103.212 28.609 103.748 28.1807C104.475 27.6332 105.354 27.3244 106.264 27.297C107.164 27.2578 108.052 27.5066 108.801 28.0072C109.349 28.3704 109.544 28.7445 109.452 28.8421Z"
                        fill="#263238"
                    />
                    <path
                        d="M91.5438 32.6964C91.5593 33.1137 91.4137 33.5209 91.1371 33.8338C90.8606 34.1467 90.4743 34.3412 90.0583 34.3771C89.8544 34.4027 89.6475 34.3869 89.4498 34.3307C89.2522 34.2744 89.0679 34.179 88.908 34.0499C88.748 33.9209 88.6158 33.7609 88.5191 33.5796C88.4224 33.3983 88.3632 33.1993 88.3451 32.9946C88.3297 32.5774 88.4753 32.1701 88.7518 31.8573C89.0283 31.5444 89.4146 31.3499 89.8306 31.314C90.0344 31.2892 90.2411 31.3055 90.4384 31.362C90.6358 31.4186 90.8198 31.5141 90.9795 31.643C91.1393 31.7719 91.2715 31.9316 91.3684 32.1125C91.4654 32.2935 91.525 32.492 91.5438 32.6964Z"
                        fill="#263238"
                    />
                    <path
                        d="M92.4333 29.0694C92.2435 29.2917 90.9912 28.4351 89.2617 28.5273C87.5323 28.6194 86.2961 29.514 86.101 29.308C86.0034 29.2158 86.1931 28.8309 86.7299 28.408C87.4584 27.8588 88.3389 27.5482 89.2508 27.5189C90.1501 27.4839 91.0377 27.7323 91.7881 28.2291C92.3303 28.5761 92.5309 28.9447 92.4333 29.0694Z"
                        fill="#263238"
                    />
                    <path
                        d="M99.1685 40.26C99.1685 40.1624 100.226 39.9347 101.961 39.642C102.4 39.5823 102.812 39.4739 102.877 39.1703C102.895 38.7132 102.774 38.2613 102.53 37.8746L101.093 34.5621C99.0872 29.8454 97.618 25.9582 97.8131 25.8877C98.0083 25.8172 99.7811 29.5634 101.782 34.2856L103.164 37.6198C103.465 38.1407 103.571 38.7519 103.462 39.3438C103.406 39.5024 103.313 39.6458 103.192 39.7625C103.071 39.8792 102.924 39.966 102.763 40.016C102.52 40.0927 102.269 40.1383 102.015 40.1516C100.269 40.3088 99.1793 40.3576 99.1685 40.26Z"
                        fill="#263238"
                    />
                    <path
                        d="M100.82 60.6817C94.9226 61.0124 89.0497 59.6985 83.8558 56.8867C83.8558 56.8867 88.3502 65.496 100.847 63.8262L100.82 60.6817Z"
                        fill="#FF9A6C"
                    />
                    <path
                        d="M93.5598 44.1027C93.774 43.747 94.0726 43.4497 94.4291 43.237C94.7856 43.0242 95.189 42.9026 95.6037 42.8828C96.1837 42.8193 96.7701 42.9113 97.3028 43.1496C97.8354 43.3878 98.295 43.7635 98.6343 44.2382C98.8762 44.6859 98.9437 45.2074 98.824 45.702C98.721 45.9514 98.824 45.8267 98.618 46.0056C97.9953 46.6961 97.2138 47.2246 96.341 47.5453C95.8687 47.6825 95.366 47.6747 94.8982 47.5229C94.4304 47.3712 94.0189 47.0824 93.717 46.6941C93.4565 46.3178 93.3041 45.8772 93.2764 45.4203C93.2487 44.9635 93.3467 44.5077 93.5598 44.1027Z"
                        fill="#FF9A6C"
                    />
                    <path
                        d="M93.8213 41.7348C94.0978 41.7023 94.1899 43.5835 95.8543 44.8413C97.5187 46.0991 99.5084 45.8063 99.5355 46.0665C99.568 46.1804 99.1072 46.4515 98.2669 46.5111C97.1681 46.5757 96.0818 46.2513 95.1983 45.5949C94.3481 44.9645 93.778 44.0267 93.6098 42.9817C93.5014 42.201 93.6803 41.7294 93.8213 41.7348Z"
                        fill="#263238"
                    />
                    <path
                        d="M93.0869 22.7151C92.9622 23.1976 91.2219 23.1434 89.2214 23.5826C87.2209 24.0217 85.6432 24.7319 85.3342 24.3416C85.1932 24.1518 85.4372 23.6801 86.0498 23.1651C86.8765 22.5242 87.838 22.0794 88.8617 21.8644C89.8853 21.6493 90.9445 21.6695 91.9592 21.9236C92.7291 22.1513 93.1357 22.482 93.0869 22.7151Z"
                        fill="#263238"
                    />
                    <path
                        d="M109.305 24.1901C108.915 24.5317 107.679 23.9353 106.053 23.6859C104.426 23.4365 103.087 23.5233 102.832 23.0733C102.729 22.8564 102.995 22.4986 103.635 22.2113C104.497 21.876 105.436 21.7878 106.345 21.9565C107.277 22.0889 108.151 22.4881 108.861 23.1058C109.36 23.5883 109.484 24.0166 109.305 24.1901Z"
                        fill="#263238"
                    />
                    <path
                        d="M97.4755 4.48828C97.4755 4.58045 97.5135 4.66719 97.5352 4.75935H97.4918L97.4755 4.48828Z"
                        fill="#263238"
                    />
                    <path
                        d="M79.1806 65.1561C79.4627 65.2355 79.7584 65.2548 80.0484 65.2127C80.3385 65.1706 80.6164 65.068 80.8643 64.9116C81.1122 64.7552 81.3245 64.5485 81.4874 64.3048C81.6503 64.0611 81.7602 63.786 81.81 63.4971C82.2081 60.9734 82.23 58.4047 81.875 55.8746C81.4684 52.394 79.1046 48.7507 78.8119 45.2539C78.2697 38.5909 78.839 34.8555 79.5817 29.266C82.7208 28.8756 85.6484 26.9944 87.714 24.5547C90.2295 21.6271 91.7475 17.9947 93.2276 14.4274C93.136 17.9562 91.9437 21.3682 89.8175 24.1861C90.5228 24.2949 91.2439 24.2368 91.9228 24.0165C92.6016 23.7962 93.2193 23.4197 93.7264 22.9174C94.7307 21.9029 95.5118 20.6897 96.0196 19.3555C97.375 16.1787 98.1287 12.7778 98.2425 9.32581C98.714 14.2651 98.1602 19.2485 96.616 23.9638C97.4943 23.6927 98.4376 23.9367 99.3267 24.0668C100.216 24.1969 101.278 24.1752 101.924 23.5246C102.314 23.0444 102.56 22.4636 102.634 21.8494C103.497 17.6517 103.636 13.3374 103.046 9.09269C104.234 11.3418 105.199 13.7025 105.925 16.1406C106.644 18.6022 106.663 21.2156 105.979 23.6873C106.08 23.7914 106.201 23.8741 106.335 23.9307C106.468 23.9872 106.612 24.0163 106.757 24.0163C106.902 24.0163 107.046 23.9872 107.179 23.9307C107.313 23.8741 107.434 23.7914 107.535 23.6873C107.931 23.2542 108.17 22.7004 108.213 22.1151C108.543 20.0061 108.175 17.8592 107.8 15.7611C109.053 17.9839 109.47 20.5645 110.164 23.015C110.858 25.4655 111.964 27.9919 114.095 29.3907C114.455 26.1846 114.349 22.9433 113.78 19.7676C113.363 17.1381 112.116 14.5304 110.961 12.1341C109.738 9.47954 108.013 7.08637 105.881 5.08622C102.184 1.83334 100.639 3.24292 98.2912 4.47359L98.0147 4.59286C98.0147 4.53323 98.0147 4.47359 98.0147 4.41938L97.9117 3.91518C91.7343 2.53375 85.2994 2.83518 79.2781 4.78804C74.8325 6.23557 74.3988 15.8099 73.3145 11.2613C72.6585 8.50717 69.0912 11.0227 69.0478 11.0986C63.8053 20.5591 58.2428 35.5657 57.988 59.2792L79.1806 65.1561Z"
                        fill="#263238"
                    />
                    <g opacity="0.3">
                        <path
                            d="M110.06 126.986C113.421 149.034 116.781 171.081 120.139 193.128L119.358 200.295C118.474 201.304 116.712 200.968 115.812 199.97C114.912 198.973 114.647 197.579 114.424 196.256C110.586 173.383 108.515 150.12 110.06 126.986Z"
                            fill="black"
                        />
                    </g>
                    <path
                        d="M110.378 71.281L121.763 73.5038C124.19 74.0083 126.437 75.1561 128.268 76.8272C130.1 78.4983 131.448 80.6311 132.172 83.0022C132.531 84.1746 132.79 85.3752 132.947 86.5913C133.939 94.2789 138.64 115.065 138.64 115.065C138.64 115.065 149.119 120.047 149.575 124.319C150.03 128.591 145.948 136.344 145.948 136.344C145.948 136.344 141.421 148.239 135.414 150.722C128.822 153.433 124.235 145.241 124.235 145.241C124.192 145.989 124.164 146.406 124.164 146.406L141.421 185.441C137.03 193.275 128.784 202.448 120.407 204.443C120.407 204.443 110.687 143.782 110.139 130.532C109.592 117.282 101.085 65.7891 101.085 65.7891L110.378 71.281Z"
                        fill="#D80027"
                    />
                    <path
                        d="M150.306 80.2102C150.306 80.2102 152.128 79.4838 152.637 81.0072C152.968 81.9939 153.635 84.6016 154.069 86.3094C154.193 86.8042 154.208 87.32 154.115 87.8215C154.021 88.323 153.82 88.7984 153.526 89.2153L152.138 91.1562C152.621 92.3849 152.697 93.7358 152.355 95.0108C152.355 95.0108 154.497 97.1794 154.226 99.0986C154.226 99.0986 156.969 100.844 156.27 103.75C155.57 106.656 147.444 115.461 147.444 115.461L142.25 117.087L135.565 112.018C135.565 112.018 134.052 103.886 134.324 100.384C134.649 96.1765 136.221 95.759 136.221 95.759L143.215 89.1773L145.709 85.6317C145.709 85.6317 148.056 86.0004 147.633 89.031L149.726 87.5509L150.268 85.0462C150.268 85.0462 148.728 81.9126 150.306 80.2102Z"
                        fill="#FFBF9D"
                    />
                    <path
                        d="M156.027 65.8056C155.604 66.0442 147.467 89.6872 147.467 89.6872L145.493 90.6739L154.249 65.2852L156.027 65.8056Z"
                        fill="#263238"
                    />
                    <path
                        d="M148.392 94.2308C148.304 94.2692 148.221 94.3165 148.143 94.3718L147.46 94.8109L144.96 96.4374C144.418 96.7735 143.876 97.1476 143.209 97.5217C142.895 97.7114 142.564 97.8958 142.217 98.0638C141.879 98.2535 141.513 98.3871 141.133 98.4596C140.949 98.4845 140.763 98.4621 140.591 98.3945C140.423 98.3114 140.29 98.171 140.217 97.9988C140.061 97.6014 139.982 97.1784 139.983 96.7518C139.958 96.5375 139.958 96.321 139.983 96.1067C140.046 95.9002 140.143 95.7059 140.271 95.532C140.538 95.1519 140.846 94.8012 141.187 94.4857C142.51 93.2677 143.934 92.1632 145.443 91.184C146.648 90.3475 147.917 89.6061 149.238 88.9666C149.487 88.8675 149.759 88.8429 150.022 88.8958C150.285 88.9487 150.527 89.0769 150.718 89.2648C151.037 89.5408 151.317 89.8601 151.547 90.2135C151.943 90.7936 152.09 91.1514 152.122 91.1406C152.101 91.0482 152.068 90.9589 152.024 90.8749C151.913 90.6261 151.784 90.3852 151.64 90.1539C151.419 89.7788 151.143 89.4389 150.821 89.1455C150.617 88.9545 150.372 88.8118 150.105 88.728C149.796 88.6323 149.463 88.6515 149.167 88.7823C147.818 89.3936 146.523 90.1191 145.296 90.9508C143.756 91.9256 142.305 93.034 140.959 94.2634C140.608 94.5925 140.29 94.9557 140.01 95.3477C139.864 95.5491 139.754 95.7748 139.685 96.0145C139.647 96.2529 139.638 96.495 139.658 96.7356C139.66 97.2001 139.75 97.6601 139.924 98.0909C140.023 98.3252 140.201 98.5171 140.428 98.6331C140.648 98.7193 140.888 98.7455 141.122 98.709C141.536 98.6339 141.933 98.4891 142.298 98.2807C142.651 98.0909 142.981 97.8958 143.301 97.7006C143.936 97.3157 144.51 96.9307 145.036 96.5838C146.083 95.8844 146.917 95.2935 147.492 94.8814L148.143 94.3989C148.322 94.3067 148.398 94.2417 148.392 94.2308Z"
                        fill="#FF9A6C"
                    />
                    <path
                        d="M152.072 98.5842C151.917 98.5347 151.757 98.5002 151.595 98.4812L150.234 98.2535L148.066 99.8258C147.177 100.758 146.114 101.859 144.889 103.008C144.58 103.296 144.26 103.588 143.924 103.865C143.614 104.16 143.244 104.385 142.839 104.526C142.636 104.573 142.423 104.556 142.23 104.479C142.036 104.401 141.87 104.267 141.755 104.092C141.487 103.722 141.313 103.292 141.246 102.84C141.23 102.62 141.287 102.401 141.408 102.217C141.519 102.003 141.642 101.797 141.777 101.599C142.043 101.197 142.319 100.812 142.623 100.444C143.208 99.7065 143.81 99.0234 144.39 98.3782C145.412 97.2345 146.51 96.1604 147.675 95.1633C148.112 94.7785 148.631 94.5002 149.193 94.3501C149.617 94.2462 150.06 94.2462 150.484 94.3501C150.848 94.4493 151.195 94.6028 151.514 94.8055C151.45 94.7274 151.373 94.6613 151.286 94.6103C151.049 94.4503 150.79 94.326 150.516 94.2416C150.077 94.1072 149.61 94.0867 149.161 94.182C148.561 94.3144 148.003 94.5918 147.534 94.9898C146.341 95.973 145.221 97.0421 144.184 98.1885C143.593 98.8282 142.986 99.5113 142.39 100.259C142.097 100.628 141.804 101.013 141.528 101.425C141.392 101.637 141.256 101.843 141.137 102.076C140.988 102.313 140.921 102.593 140.947 102.873C141.018 103.368 141.203 103.84 141.49 104.25C141.646 104.457 141.854 104.621 142.091 104.727C142.333 104.828 142.602 104.847 142.856 104.781C143.303 104.629 143.712 104.381 144.054 104.055C144.395 103.773 144.715 103.474 145.024 103.187C146.25 102.016 147.301 100.905 148.174 99.9342L150.305 98.3077H150.256L151.622 98.4704C151.769 98.521 151.919 98.5591 152.072 98.5842Z"
                        fill="#FF9A6C"
                    />
                    <path
                        d="M143.555 104.136C143.575 104.297 143.619 104.454 143.685 104.602C143.741 104.788 143.809 104.969 143.891 105.145C143.961 105.262 144.056 105.364 144.168 105.444C144.28 105.523 144.407 105.578 144.542 105.605C145.206 105.705 145.884 105.673 146.537 105.513C147.308 105.265 148.05 104.932 148.749 104.521C149.638 104.028 150.451 103.529 151.145 103.074H150.988L151.145 103.225C151.31 103.42 151.532 103.556 151.779 103.616C151.979 103.68 152.184 103.725 152.392 103.751C152.725 103.793 153.06 103.812 153.395 103.811C153.685 103.84 153.979 103.809 154.257 103.719C154.183 103.709 154.108 103.709 154.035 103.719L153.395 103.686C153.124 103.686 152.793 103.632 152.424 103.567C152.23 103.532 152.037 103.483 151.85 103.421C151.655 103.369 151.481 103.259 151.351 103.106L151.194 102.944L151.129 102.873L151.036 102.927C150.337 103.361 149.518 103.849 148.64 104.337C147.963 104.737 147.247 105.067 146.504 105.324C145.889 105.487 145.251 105.54 144.617 105.481C144.508 105.461 144.404 105.419 144.311 105.359C144.218 105.298 144.138 105.22 144.075 105.128C143.972 104.955 143.907 104.765 143.837 104.63C143.771 104.45 143.676 104.284 143.555 104.136Z"
                        fill="#FF9A6C"
                    />
                    <path
                        d="M135.33 109.85L146.965 115.575L146.423 126.472C146.423 126.472 138.095 127.556 130.7 118.161L135.33 109.85Z"
                        fill="#FFBF9D"
                    />
                    <path
                        d="M141.447 185.436C141.426 185.368 141.401 185.301 141.371 185.236L141.127 184.656L140.173 182.438L136.584 174.214C133.505 167.215 129.206 157.456 124.315 146.353V146.413C124.402 143.425 124.516 140.308 124.662 137.131C124.999 129.964 125.389 123.138 125.649 116.931C125.909 110.723 126.072 105.117 126.121 100.417C126.17 95.7164 126.121 91.9051 126.121 89.2757L126.05 86.2505C126.05 85.9144 126.05 85.6542 126.05 85.4644C126.05 85.2747 126.05 85.1934 126.05 85.1934C126.046 85.2837 126.046 85.3741 126.05 85.4644C126.05 85.6542 126.05 85.9144 126.05 86.2505C126.05 86.9553 126.05 87.9746 126.05 89.2811C126.05 91.9106 126.05 95.711 125.985 100.411C125.92 105.112 125.736 110.712 125.443 116.914C125.15 123.117 124.793 129.953 124.435 137.12C124.288 140.297 124.18 143.415 124.088 146.407V146.434V146.467L136.438 174.284C137.972 177.76 139.219 180.546 140.086 182.498L141.084 184.666C141.192 184.91 141.285 185.095 141.344 185.236C141.374 185.305 141.409 185.372 141.447 185.436Z"
                        fill="#263238"
                    />
                    <path
                        d="M110.782 123.946C110.805 123.902 110.822 123.854 110.831 123.805L110.934 123.382C111.026 122.992 111.151 122.444 111.313 121.756C111.606 120.335 112.029 118.27 112.398 115.7C112.766 113.13 113.167 110.056 113.482 106.635C113.796 103.214 114.024 99.4408 114.208 95.4777C114.273 94.0031 114.338 92.5501 114.404 91.1405C114.436 90.4303 114.463 89.731 114.496 89.0424L114.544 88.0124V87.5027V87.2425C114.544 87.1991 114.544 87.1503 114.544 87.1015V87.0094C114.535 86.9893 114.522 86.9709 114.507 86.9552C114.487 86.9361 114.462 86.9221 114.436 86.9145C114.41 86.907 114.382 86.906 114.355 86.9118C114.257 86.966 114.284 86.9714 114.257 86.9985L114.452 86.9552L111.405 84.83V85.0522L114.713 83.5017L114.805 83.4583L114.778 83.3661C114.279 81.4849 113.693 79.8151 113.151 78.335C112.609 76.855 112.137 75.5809 111.731 74.54C111.324 73.4991 110.993 72.6804 110.766 72.1274C110.652 71.8672 110.565 71.6558 110.5 71.4985C110.471 71.4258 110.436 71.3552 110.397 71.2871C110.407 71.364 110.429 71.439 110.462 71.5094L110.701 72.1545L111.568 74.6105C111.953 75.6622 112.419 76.9471 112.934 78.4055C113.449 79.8639 114.019 81.5608 114.501 83.4258L114.566 83.2902L111.248 84.8245L111.048 84.9167L111.227 85.0468L114.263 87.1829L114.458 87.1395C114.458 87.1395 114.36 87.069 114.371 87.069C114.354 87.0849 114.334 87.0962 114.311 87.1015H114.268V87.2154V87.4594V87.9636C114.268 88.3051 114.235 88.6467 114.214 88.9882C114.181 89.6822 114.154 90.3815 114.122 91.0917C114.057 92.5067 113.986 93.9597 113.921 95.4289C113.737 99.3866 113.504 103.155 113.216 106.575C112.929 109.996 112.571 113.081 112.208 115.635C111.845 118.188 111.46 120.27 111.199 121.696C111.058 122.397 110.95 122.939 110.874 123.322L110.798 123.751C110.787 123.913 110.782 123.946 110.782 123.946Z"
                        fill="#263238"
                    />
                    <path
                        d="M125.578 119.067C125.627 119.067 125.649 118.579 125.914 117.847C126.251 116.894 126.875 116.07 127.701 115.487C128.526 114.904 129.512 114.592 130.522 114.594C131.298 114.594 131.769 114.74 131.78 114.692C131.392 114.495 130.963 114.395 130.528 114.399C129.465 114.336 128.412 114.635 127.54 115.246C126.668 115.857 126.028 116.745 125.724 117.766C125.569 118.18 125.518 118.628 125.578 119.067Z"
                        fill="#263238"
                    />
                    <path
                        d="M129.641 116.387C129.756 116.665 129.892 116.933 130.048 117.19C130.207 117.504 130.389 117.806 130.59 118.095C130.829 118.431 131.073 118.838 131.409 119.234C131.571 119.434 131.745 119.646 131.918 119.868C132.092 120.09 132.314 120.291 132.52 120.519C132.966 120.993 133.437 121.443 133.93 121.869C136.204 123.82 138.898 125.221 141.802 125.962C142.434 126.124 143.074 126.251 143.721 126.341C144.019 126.385 144.312 126.439 144.594 126.46L145.407 126.515C145.875 126.541 146.343 126.541 146.811 126.515C147.163 126.519 147.514 126.497 147.863 126.45C148.162 126.43 148.46 126.387 148.752 126.32C148.752 126.249 147.472 126.45 145.423 126.32L144.621 126.249C144.339 126.222 144.052 126.162 143.753 126.119C143.157 126.038 142.528 125.87 141.872 125.718C139.015 124.957 136.361 123.576 134.098 121.673C133.606 121.261 133.135 120.823 132.688 120.361C132.482 120.139 132.265 119.944 132.086 119.727C131.907 119.51 131.729 119.31 131.544 119.114C130.27 117.51 129.701 116.355 129.641 116.387Z"
                        fill="#263238"
                    />
                    <path
                        d="M142.311 104.607C142.225 104.607 142.263 105.719 141.888 107.015C141.514 108.31 140.907 109.243 140.983 109.292C141.529 108.653 141.931 107.903 142.16 107.095C142.389 106.286 142.441 105.438 142.311 104.607Z"
                        fill="#FF9A6C"
                    />
                    <path
                        d="M141.932 98.4648C141.857 98.492 141.965 98.9528 142.041 99.5491C142.117 100.145 142.133 100.59 142.214 100.595C142.296 100.601 142.404 100.118 142.323 99.5112C142.241 98.904 142.019 98.4648 141.932 98.4648Z"
                        fill="#FF9A6C"
                    />
                    <path
                        d="M57.3563 153.042L46.3127 78.7193L66.6975 71.2485C66.6975 71.2485 71.4738 66.8571 74.0164 64.5801L74.911 76.0736C74.911 76.0736 77.6922 123.061 73.3496 146.715C69.007 170.369 69.625 169.578 66.4481 184.975C64.1385 196.175 63.7373 205.961 60.2784 206.948C56.8195 207.935 44.7568 190.299 44.7568 190.299L57.3563 153.042Z"
                        fill="#D80027"
                    />
                    <path
                        d="M46.3333 78.7207C46.3333 78.7207 40.1529 81.7513 39.2312 90.3823C38.4126 97.9723 37.0626 144.597 37.2415 148.343C37.4204 152.089 40.6516 162.726 46.458 163.095C55.6474 163.681 58.1738 156.589 58.1738 156.589L55.7287 132.784L46.3333 78.7207Z"
                        fill="#D80027"
                    />
                    <path
                        d="M69.7089 121.447V121.306C69.7089 121.198 69.6655 121.068 69.633 120.9C69.5571 120.526 69.4541 120.005 69.324 119.349C69.08 117.994 68.7059 116.037 68.359 113.602C67.6325 108.723 67.0145 101.962 66.6566 94.4645C66.5699 92.6971 66.4886 90.9677 66.4127 89.2978C66.3747 88.4629 66.3368 87.6389 66.2988 86.8365C66.2988 86.4353 66.2555 86.0341 66.2392 85.6438C66.2392 85.4486 66.2392 85.2534 66.2121 85.0583V84.7709C66.2092 84.7276 66.2092 84.6841 66.2121 84.6408C66.2105 84.6264 66.2105 84.6118 66.2121 84.5974C66.2015 84.6078 66.1886 84.6157 66.1745 84.6204C66.1605 84.6251 66.1455 84.6265 66.1308 84.6245C66.0982 84.6245 66.044 84.5866 66.0548 84.592L66.2446 84.63L69.2806 82.4939L69.4541 82.3692L69.2644 82.2825L65.941 80.7536L66.0006 80.8783C66.298 79.5449 66.5243 78.1967 66.6783 76.8393C66.8301 75.6412 66.9819 74.6111 67.1446 73.7816C67.3072 72.9521 67.4861 72.307 67.6162 71.8787C67.6813 71.6781 67.7355 71.5208 67.7735 71.3907C67.7969 71.337 67.8133 71.2806 67.8222 71.2227C67.7894 71.2711 67.7639 71.3241 67.7464 71.3799C67.703 71.4883 67.6325 71.6455 67.5566 71.857C67.3374 72.4778 67.1563 73.1115 67.0145 73.7545C66.8355 74.5894 66.6675 75.6195 66.4723 76.8122C66.2771 78.0049 66.1199 79.3657 65.7621 80.8241V80.9108L65.8434 80.9488L69.1505 82.5047V82.2933L66.1037 84.4185L66.2934 84.4565V84.4185C66.2661 84.3904 66.2295 84.373 66.1904 84.3697C66.1532 84.367 66.1163 84.3787 66.0874 84.4023L66.0603 84.4348C66.0573 84.4509 66.0573 84.4674 66.0603 84.4836C66.0573 84.516 66.0573 84.5487 66.0603 84.5812C66.0603 84.6408 66.0603 84.6896 66.0603 84.7384V85.0366V85.6275C66.0603 86.0233 66.1037 86.419 66.1199 86.8202C66.1579 87.628 66.1904 88.4467 66.2283 89.2816C66.3042 90.955 66.3838 92.6772 66.4669 94.4482C66.7276 100.862 67.3248 107.257 68.256 113.608C68.6246 116.042 69.0312 117.994 69.3077 119.344C69.4649 119.994 69.5842 120.515 69.671 120.889L69.7686 121.285C69.7391 121.335 69.7189 121.39 69.7089 121.447Z"
                        fill="#263238"
                    />
                    <g opacity="0.3">
                        <path
                            d="M99.6858 159.754C99.6858 159.754 101.079 161.565 98.016 162.118C94.9528 162.671 60.2771 160.112 60.2771 160.112L53.6846 163.94L54.5466 161.175L60.0657 155.309L99.6858 159.754Z"
                            fill="black"
                        />
                    </g>
                    <path
                        d="M58.0094 102.075L101.674 104.303C102.17 104.328 102.639 104.535 102.99 104.886C103.342 105.236 103.551 105.705 103.577 106.2L106.477 157.992C106.493 158.274 106.449 158.556 106.348 158.82C106.248 159.084 106.093 159.323 105.893 159.523C105.694 159.723 105.454 159.879 105.191 159.98C104.927 160.082 104.645 160.126 104.363 160.112L61.9997 157.894C61.5136 157.869 61.0531 157.669 60.704 157.33C60.3549 156.99 60.141 156.536 60.1021 156.051L55.9059 104.243C55.8832 103.958 55.9216 103.672 56.0183 103.403C56.1151 103.134 56.2681 102.888 56.4672 102.683C56.6663 102.478 56.9068 102.318 57.1727 102.213C57.4387 102.108 57.7239 102.061 58.0094 102.075Z"
                        fill="#263238"
                    />
                    <path
                        d="M48.8509 90.7227C48.8651 90.8793 48.8886 91.035 48.9214 91.1889C48.9865 91.5196 49.0732 91.9588 49.1762 92.5117L50.1358 97.3911C50.9328 101.495 51.9737 107.15 52.9712 113.477C53.9688 119.803 54.7278 125.501 55.232 129.649C55.4814 131.709 55.682 133.379 55.8229 134.566C55.8934 135.108 55.9476 135.574 55.9856 135.905C55.9996 136.06 56.025 136.214 56.0615 136.366C56.0759 136.211 56.0759 136.055 56.0615 135.9C56.0289 135.564 55.9964 135.119 55.9476 134.555C55.8446 133.39 55.6766 131.709 55.4489 129.627C54.9934 125.469 54.2615 119.738 53.2803 113.428C52.299 107.117 51.2038 101.446 50.3581 97.3531C49.9352 95.3038 49.5774 93.6503 49.3117 92.5117C49.1816 91.9696 49.084 91.525 49.0081 91.1997C48.9693 91.0366 48.9167 90.877 48.8509 90.7227Z"
                        fill="#263238"
                    />
                    <path
                        d="M57.1211 135.993C57.1211 135.993 54.6056 136.139 52.3828 139.858L48.1269 142.569C48.1269 142.569 44.4024 148.419 48.1269 153.884C51.8515 159.349 56.3459 154.702 56.3459 154.702C56.3459 154.702 60.8457 156.931 62.098 156.546C63.3504 156.161 71.8567 151.574 73.1253 150.522C73.494 150.208 76.2372 147.058 76.2372 147.058C76.2372 147.058 80.0323 146.245 80.4768 145.372C80.9214 144.499 80.9431 142.504 78.3787 142.699C78.3787 142.699 80.8726 141.946 81.1599 140.688C81.4473 139.43 81.1599 137.793 76.7414 138.422C72.3229 139.051 63.8546 141.404 62.8733 141.165C62.7708 141.107 62.6872 141.021 62.6323 140.917C62.5775 140.813 62.5538 140.695 62.5641 140.578C62.5744 140.461 62.6182 140.349 62.6904 140.256C62.7625 140.163 62.8599 140.093 62.9709 140.054C63.2853 139.907 73.2717 136.025 74.871 135.586C76.6005 135.115 76.8499 131.249 73.2717 132.171C69.6935 133.092 58.0374 135.895 58.0374 135.895L57.1211 135.993Z"
                        fill="#FFBF9D"
                    />
                    <path
                        d="M63.696 150.798C64.3234 150.657 64.9409 150.476 65.5447 150.255C66.6778 149.881 68.2555 149.383 69.9903 148.873C71.7252 148.363 73.3245 147.946 74.4793 147.648C75.1116 147.513 75.7331 147.331 76.3389 147.106C76.1654 147.104 75.9924 147.124 75.8238 147.165C75.4931 147.219 75.016 147.312 74.4305 147.431C73.2649 147.68 71.6601 148.071 69.909 148.58C68.1579 149.09 66.5965 149.627 65.4797 150.044C64.9375 150.255 64.4713 150.429 64.1622 150.586C63.9982 150.636 63.8414 150.707 63.696 150.798Z"
                        fill="#FF9A6C"
                    />
                    <path
                        d="M62.2687 146.174C62.4864 146.18 62.7043 146.166 62.9192 146.131C63.3367 146.082 63.9439 146.006 64.6866 145.892C66.1721 145.67 68.2214 145.318 70.4659 144.808C72.7104 144.298 74.7218 143.816 76.1693 143.404C76.8958 143.203 77.4759 143.03 77.8771 142.9C78.0897 142.843 78.2966 142.767 78.4951 142.672C78.2762 142.687 78.0597 142.727 77.85 142.791L76.1205 143.214C74.6621 143.572 72.6454 144.054 70.4063 144.531C68.1672 145.009 66.1287 145.399 64.6541 145.67L62.903 145.995C62.6845 146.026 62.4711 146.086 62.2687 146.174Z"
                        fill="#FF9A6C"
                    />
                    <path
                        d="M50.1599 145.176C50.1364 145.329 50.1364 145.484 50.1599 145.637C50.1799 146.051 50.2343 146.462 50.3226 146.867C50.8971 149.597 52.4858 152.007 54.7682 153.612C55.111 153.853 55.4738 154.066 55.8525 154.246C56.1181 154.376 56.2699 154.441 56.2754 154.419C56.2808 154.398 55.7332 154.078 54.8983 153.449C53.8289 152.626 52.9037 151.631 52.1605 150.505C51.4205 149.375 50.8711 148.132 50.534 146.824C50.2575 145.821 50.2141 145.17 50.1599 145.176Z"
                        fill="#FF9A6C"
                    />
                    <path
                        d="M48.7536 155.309C48.797 155.271 48.2114 154.707 47.55 153.596C46.6815 152.154 46.198 150.513 46.1458 148.831C46.0936 147.149 46.4744 145.481 47.2518 143.989C47.8374 142.839 48.3903 142.238 48.3361 142.205C48.1858 142.321 48.0526 142.458 47.9404 142.612C47.6069 143.012 47.3111 143.442 47.0567 143.897C46.2112 145.405 45.7931 147.115 45.8476 148.843C45.902 150.571 46.4269 152.252 47.3657 153.704C47.6446 154.14 47.9621 154.55 48.3145 154.929C48.4432 155.075 48.5909 155.203 48.7536 155.309Z"
                        fill="#263238"
                    />
                    <path
                        d="M38.9199 136.719C38.9524 136.767 39.6355 136.241 40.8662 135.634C41.5877 135.287 42.3366 135.001 43.1053 134.778C44.0412 134.513 44.9994 134.336 45.9678 134.246C46.9459 134.182 47.9278 134.209 48.9008 134.328C49.693 134.432 50.4747 134.605 51.2375 134.843C52.5441 135.255 53.2977 135.672 53.3193 135.618C53.1623 135.495 52.9912 135.391 52.8097 135.309C52.6058 135.191 52.3939 135.088 52.1754 135C51.8908 134.869 51.5994 134.753 51.3025 134.653C50.5334 134.384 49.74 134.189 48.9334 134.073C47.9438 133.936 46.943 133.9 45.9461 133.964C44.9512 134.048 43.9669 134.229 43.0077 134.507C42.2287 134.745 41.4736 135.055 40.7524 135.434C40.4753 135.577 40.2057 135.735 39.9446 135.905C39.7407 136.026 39.5451 136.16 39.359 136.306C39.196 136.425 39.0485 136.563 38.9199 136.719Z"
                        fill="#263238"
                    />
                    <g opacity="0.3">
                        <path
                            d="M67.4523 72.1875C67.4523 72.1875 63.7711 80.7751 64.2862 81.4907C64.8012 82.2064 66.7367 82.716 66.7367 82.716C66.7367 82.716 63.5651 84.0822 63.5705 85.302C63.5705 87.7254 66.9698 102.071 66.9698 102.071L66.1295 84.5485L69.1709 82.4178L65.8584 80.8781L67.4523 72.1875Z"
                            fill="black"
                        />
                    </g>
                    <path
                        d="M58.1898 103.919C58.2705 103.933 58.352 103.942 58.4338 103.946L59.1548 104L61.9252 104.185L72.1067 104.803L87.2163 105.681L96.1347 106.191L98.4768 106.321L99.6586 106.391C99.8538 106.391 100.071 106.391 100.244 106.424C100.416 106.449 100.578 106.522 100.71 106.635C100.843 106.75 100.941 106.901 100.992 107.069C101.031 107.247 101.053 107.429 101.057 107.611L101.139 108.82L101.301 111.265C102.207 124.38 103.025 136.247 103.616 144.878C103.925 149.167 104.158 152.642 104.348 155.054C104.44 156.247 104.516 157.174 104.565 157.819C104.592 158.128 104.614 158.361 104.625 158.54C104.636 158.719 104.657 158.784 104.657 158.784C104.657 158.784 104.657 158.703 104.657 158.54C104.657 158.378 104.657 158.123 104.619 157.819C104.581 157.169 104.527 156.242 104.462 155.049C104.31 152.631 104.088 149.156 103.817 144.867C103.242 136.258 102.456 124.385 101.583 111.254C101.529 110.437 101.475 109.624 101.421 108.815L101.339 107.606C101.336 107.392 101.311 107.18 101.263 106.971C101.192 106.76 101.065 106.573 100.895 106.429C100.72 106.282 100.508 106.187 100.282 106.153C100.06 106.153 99.8809 106.153 99.6749 106.115L98.493 106.05L96.1509 105.92L87.2272 105.426L72.1176 104.608L61.9306 104.065L59.1602 103.935L58.4392 103.908L58.1898 103.919Z"
                        fill="#455A64"
                    />
                </svg>
                <div className="flex flex-col justify-start items-start w-full max-w-[696px] relative gap-[27px]">
                    <div className="flex flex-col justify-start items-start gap-[5px]">
                        <div className="flex justify-center items-center w-full">
                            <svg
                                width={70}
                                height={71}
                                viewBox="0 0 70 71"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="spin-animation max-w-[70px] h-[70px] relative"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M38.6445 16.9036C38.6445 14.8984 37.0039 13.2578 34.9987 13.2578C32.9023 13.2578 31.3529 14.8984 31.3529 16.9036C31.3529 19 32.9023 20.5495 34.9987 20.5495C37.0039 20.5495 38.6445 19 38.6445 16.9036ZM34.9987 51.1745C32.9023 51.1745 31.3529 52.8151 31.3529 54.8203C31.3529 56.9167 32.9023 58.4661 34.9987 58.4661C37.0039 58.4661 38.6445 56.9167 38.6445 54.8203C38.6445 52.8151 37.0039 51.1745 34.9987 51.1745ZM57.6029 35.862C57.6029 33.8568 55.9622 32.2161 53.957 32.2161C51.8607 32.2161 50.3112 33.8568 50.3112 35.862C50.3112 37.9583 51.8607 39.5078 53.957 39.5078C55.9622 39.5078 57.6029 37.9583 57.6029 35.862ZM19.6862 35.862C19.6862 33.8568 18.0456 32.2161 16.0404 32.2161C13.944 32.2161 12.3945 33.8568 12.3945 35.862C12.3945 37.9583 13.944 39.5078 16.0404 39.5078C18.0456 39.5078 19.6862 37.9583 19.6862 35.862ZM21.5091 18.8177C19.5039 18.8177 17.8633 20.4583 17.8633 22.4635C17.8633 24.5599 19.5039 26.1094 21.5091 26.1094C23.6055 26.1094 25.1549 24.5599 25.1549 22.4635C25.1549 20.4583 23.6055 18.8177 21.5091 18.8177ZM48.3971 45.7057C46.3008 45.7057 44.7513 47.2552 44.7513 49.3516C44.7513 51.3568 46.3008 52.9974 48.3971 52.9974C50.4023 52.9974 52.043 51.3568 52.043 49.3516C52.043 47.2552 50.4023 45.7057 48.3971 45.7057ZM21.5091 45.7057C19.5039 45.7057 17.8633 47.2552 17.8633 49.3516C17.8633 51.3568 19.5039 52.9974 21.5091 52.9974C23.6055 52.9974 25.1549 51.3568 25.1549 49.3516C25.1549 47.2552 23.6055 45.7057 21.5091 45.7057Z"
                                    fill="#D80027"
                                />
                            </svg>
                        </div>
                        <p className="w-full max-w-[696px] text-base font-bold text-left text-[#373737]">
                            Buscando por favor espere...
                        </p>
                        <p className="w-full max-w-[696px] text-base text-left text-[#373737]">
                            Estamos buscando para usted a el profesional más
                            idóneo para su trabajo, este proceso puede tardar
                            varios minutos por favor no cierre esta ventana.
                        </p>
                    </div>
                    <p className="w-full max-w-[696px] text-[21px] font-bold text-center text-[#373737]">
                       Verifique Si Hay Candidatos
                    </p>
                </div>
            </div>
            {/* <div className="flex flex-grow-0 flex-shrink-0 justify-end w-full">
                <Button
                    text="Ver candidato"
                    color={COLOR.RED}
                    size={SIZE.MEDIUM}
                    addClass={"h-11 "}
                    onClick={goToNext}
                />
            </div> */}
        </div>
    );
}

export default SearchingProfessional;
