import { noop } from "../../utils/noop";
import Pagination from "../Pagination";
import Input from "../ui/Input";
import ProposalCardUnregistred from "./ProposalCardUnregistred";

function JobProposalsUnregistred({
    formData,
    handleChange = noop,
    showContactButton,
    showContractButton,
    CustomProfessionalButton = null,
}) {
    return (
        <>
            <ProposalCardUnregistred
                CustomProfessionalButton={CustomProfessionalButton}
                showContactButton={showContactButton}
                contactor={showContractButton}
            />
            <div className="flex flex-col gap-10 justify-start items-center self-stretch mt-10 lg:gap-0 lg:flex-row">
                <div className="flex justify-start items-center flex-grow relative gap-2.5">
                    <p className="  text-base text-left text-[#373737]">
                        Postulaciones por página:
                    </p>

                    <div className="flex flex-col justify-center items-start  gap-[5px]">
                        <Input
                            name="order"
                            value={formData.order}
                            onChange={(e) => handleChange(e.target)}
                            type="dropdown"
                            // placeholder="Order"
                            selectList={["10", "50", "100"]}
                            inputClassName={
                                // py-2.5
                                " text-base w-[100px] text-left text-[#373737] flex  items-center  self-stretch  gap-2.5 px-3 py-1.5 rounded h-9 bg-white border-t border-r border-b border-l border-[#292929]"
                            }
                        />
                    </div>
                </div>
                <Pagination />
            </div>
        </>
    );
}

export default JobProposalsUnregistred;
