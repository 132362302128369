import React from "react";

import useToggleHeaderFooter from "../hooks/useToggleHeaderFooter";
import Socials from "./Socials";

function Footer() {
    const { toggleValue } = useToggleHeaderFooter();

    return (
        // gap-[30px]
        toggleValue ? (
            <div className="relative flex flex-col items-center justify-evenly lg:justify-center min-w-fit min-h-fit lg:h-auto ">
                <svg
                    width={30}
                    height={30}
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer w-[30px] h-[30px] absolute right-0 top-[10%] mx-5"
                    preserveAspectRatio="xMidYMid meet"
                    onClick={() =>
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                            left: 0
                        })
                    }
                >
                    <rect width={30} height={30} rx={4} fill="#292929" />
                    <path
                        d="M9.49219 12.2266L10.2734 13.0078C10.4688 13.1641 10.7422 13.1641 10.9375 12.9688L13.9844 9.84375V23.2812C13.9844 23.5547 14.1797 23.75 14.4531 23.75H15.5469C15.7812 23.75 16.0156 23.5547 16.0156 23.2812V9.84375L19.0234 12.9688C19.2188 13.1641 19.4922 13.1641 19.6875 13.0078L20.4688 12.2266C20.625 12.0312 20.625 11.7578 20.4688 11.5625L15.3125 6.40625C15.1172 6.25 14.8438 6.25 14.6484 6.40625L9.49219 11.5625C9.33594 11.7578 9.33594 12.0312 9.49219 12.2266Z"
                        fill="#F2F2F2"
                    />
                </svg>
                {/* gap-[30px] */}
                <div className="flex flex-col items-center justify-center ">
                    <div className="flex justify-start items-center  relative gap-[14.70588207244873px] m-5">
                        <svg
                            width={50}
                            height={50}
                            viewBox="0 0 50 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className=" w-[48.67px] h-[49.81px]"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M48.734 49.6183L1.56355 49.8054C1.36604 49.8054 1.17662 49.7269 1.03696 49.5872C0.897303 49.4476 0.818848 49.2581 0.818848 49.0606C0.818848 48.8631 0.897303 48.6737 1.03696 48.5341C1.17662 48.3944 1.36604 48.3159 1.56355 48.3159L48.734 48.1289C48.8321 48.1285 48.9293 48.1474 49.02 48.1846C49.1108 48.2219 49.1933 48.2767 49.2628 48.3459C49.3323 48.4151 49.3874 48.4973 49.4251 48.5879C49.4627 48.6784 49.4821 48.7755 49.4821 48.8736C49.4821 48.9717 49.4627 49.0688 49.4251 49.1594C49.3874 49.25 49.3323 49.3322 49.2628 49.4014C49.1933 49.4706 49.1108 49.5254 49.02 49.5626C48.9293 49.5998 48.8321 49.6188 48.734 49.6183Z"
                                fill="#D80027"
                            />
                            <path
                                d="M23.16 17.4523C22.9625 17.4523 22.7731 17.3739 22.6334 17.2342C22.4938 17.0946 22.4153 16.9051 22.4153 16.7076C22.4153 12.8672 21.3734 10.2758 19.1426 8.54923C19.0649 8.48965 18.9997 8.41532 18.9508 8.33052C18.9018 8.24571 18.8701 8.15208 18.8574 8.05499C18.8447 7.95791 18.8512 7.85926 18.8767 7.76471C18.9021 7.67016 18.946 7.58155 19.0057 7.50396C19.0655 7.42604 19.1402 7.36073 19.2255 7.3118C19.3107 7.26286 19.4047 7.23128 19.5022 7.21886C19.5997 7.20644 19.6986 7.21344 19.7934 7.23945C19.8881 7.26545 19.9768 7.30995 20.0543 7.37039C22.6458 9.37409 23.9048 12.4264 23.9048 16.721C23.9013 16.9162 23.8212 17.1022 23.6819 17.239C23.5427 17.3758 23.3552 17.4524 23.16 17.4523Z"
                                fill="#D80027"
                            />
                            <path
                                d="M27.4912 17.4527C27.3931 17.4532 27.2959 17.4342 27.2052 17.397C27.1145 17.3598 27.032 17.305 26.9625 17.2358C26.893 17.1666 26.8378 17.0844 26.8002 16.9938C26.7625 16.9032 26.7432 16.8061 26.7432 16.708C26.7432 12.4268 28.0055 9.36113 30.5969 7.35743C30.6742 7.29709 30.7626 7.25265 30.857 7.22669C30.9515 7.20073 31.0502 7.19375 31.1474 7.20618C31.2446 7.2186 31.3384 7.25017 31.4233 7.29905C31.5083 7.34794 31.5827 7.41319 31.6422 7.491C31.7025 7.56821 31.747 7.65662 31.7729 7.75111C31.7989 7.8456 31.8059 7.9443 31.7934 8.0415C31.781 8.1387 31.7495 8.23248 31.7006 8.3174C31.6517 8.40232 31.5864 8.47672 31.5086 8.53627C29.2745 10.2628 28.2359 12.8542 28.2359 16.6947C28.2377 16.7936 28.2197 16.8919 28.1831 16.9838C28.1465 17.0757 28.0919 17.1593 28.0226 17.2299C27.9532 17.3005 27.8706 17.3565 27.7793 17.3948C27.6881 17.4331 27.5901 17.4528 27.4912 17.4527Z"
                                fill="#D80027"
                            />
                            <path
                                d="M40.983 7.21387C38.4664 7.2174 36.0539 8.21892 34.2747 9.99876C32.4955 11.7786 31.4948 14.1914 31.4922 16.7081C31.4922 16.9056 31.5706 17.095 31.7103 17.2347C31.85 17.3743 32.0394 17.4528 32.2369 17.4528C32.3347 17.4528 32.4315 17.4335 32.5219 17.3961C32.6122 17.3587 32.6943 17.3038 32.7635 17.2347C32.8326 17.1655 32.8875 17.0834 32.9249 16.9931C32.9624 16.9027 32.9816 16.8059 32.9816 16.7081C32.9816 14.5824 33.826 12.5438 35.3291 11.0408C36.8322 9.53768 38.8708 8.69327 40.9964 8.69327C41.8183 8.69546 42.6316 8.8595 43.3901 9.17603C44.1485 9.49256 44.8372 9.95538 45.4168 10.5381C45.9963 11.1207 46.4555 11.8119 46.768 12.572C47.0804 13.3321 47.2401 14.1463 47.238 14.9682C47.2335 16.2522 46.721 17.4823 45.8124 18.3897C44.9039 19.297 43.673 19.8078 42.389 19.8105C41.3991 19.8096 40.4499 19.4159 39.7499 18.716C39.0499 18.016 38.6563 17.0668 38.6554 16.0769C38.6563 15.3252 38.9553 14.6046 39.4868 14.0731C40.0183 13.5415 40.739 13.2426 41.4907 13.2417C42.0137 13.242 42.5181 13.4359 42.9066 13.786C42.5626 13.893 42.262 14.1073 42.0486 14.3976C41.8353 14.6878 41.7205 15.0388 41.7211 15.399C41.7211 15.8471 41.8991 16.277 42.216 16.5938C42.5329 16.9107 42.9627 17.0888 43.4109 17.0888C44.3393 17.0888 45.0973 16.4209 45.0973 15.1786C45.0973 13.1148 43.4843 11.7489 41.4907 11.7489C40.3393 11.7489 39.235 12.2063 38.4209 13.0205C37.6067 13.8346 37.1493 14.9389 37.1493 16.0903C37.152 17.4776 37.7054 18.807 38.6879 19.7864C39.6704 20.7658 41.0017 21.315 42.389 21.3132C44.0718 21.3132 45.6857 20.6447 46.8756 19.4548C48.0656 18.2649 48.734 16.651 48.734 14.9682C48.7345 13.95 48.5343 12.9418 48.145 12.001C47.7557 11.0602 47.1848 10.2054 46.465 9.48526C45.7452 8.76516 44.8906 8.19393 43.95 7.8042C43.0094 7.41446 42.0012 7.21387 40.983 7.21387Z"
                                fill="#D80027"
                            />
                            <path
                                d="M8.92741 7.21387C11.4446 7.21652 13.858 8.21765 15.6379 9.99758C17.4178 11.7775 18.419 14.1909 18.4216 16.7081C18.4216 16.9056 18.3432 17.095 18.2035 17.2347C18.0638 17.3743 17.8744 17.4528 17.6769 17.4528C17.4794 17.4528 17.29 17.3743 17.1503 17.2347C17.0107 17.095 16.9322 16.9056 16.9322 16.7081C16.9322 14.5824 16.0878 12.5438 14.5847 11.0408C13.0816 9.53768 11.0431 8.69327 8.9174 8.69327C8.09556 8.69546 7.28219 8.8595 6.52374 9.17603C5.7653 9.49256 5.07663 9.95538 4.49705 10.5381C3.91747 11.1207 3.45834 11.8119 3.14586 12.572C2.83338 13.3321 2.67368 14.1463 2.67587 14.9682C2.67764 16.2546 3.18941 17.4877 4.09901 18.3973C5.00861 19.3069 6.24179 19.8187 7.52816 19.8205C8.51809 19.8196 9.46722 19.426 10.1672 18.726C10.8672 18.026 11.2608 17.0769 11.2617 16.0869C11.2608 15.3347 10.9614 14.6135 10.4291 14.0819C9.89691 13.5503 9.1754 13.2517 8.42314 13.2517C7.9011 13.2519 7.39773 13.4459 7.01054 13.796C7.3545 13.903 7.65518 14.1173 7.86851 14.4076C8.08184 14.6979 8.19662 15.0488 8.19606 15.409C8.19518 15.8569 8.01687 16.2862 7.70017 16.6029C7.38346 16.9196 6.95416 17.0979 6.50627 17.0988C5.57789 17.0988 4.81649 16.4309 4.81649 15.1886C4.81649 13.1248 6.4328 11.7589 8.42314 11.7589C9.57454 11.7589 10.6788 12.2163 11.4929 13.0305C12.3071 13.8446 12.7645 14.9489 12.7645 16.1003C12.7592 17.4853 12.2051 18.8117 11.2235 19.7888C10.242 20.7659 8.91315 21.3141 7.52816 21.3132C5.84535 21.3132 4.23147 20.6448 3.04154 19.4548C1.85161 18.2649 1.18311 16.651 1.18311 14.9682C1.1831 12.9134 1.99871 10.9425 3.45076 9.4886C4.90281 8.03467 6.87257 7.21652 8.92741 7.21387Z"
                                fill="#D80027"
                            />
                            <path
                                d="M34.9151 5.10319H15.005C14.8074 5.10319 14.618 5.02473 14.4784 4.88507C14.3387 4.74541 14.2603 4.55599 14.2603 4.35848C14.2603 4.26068 14.2795 4.16384 14.3169 4.07349C14.3544 3.98314 14.4092 3.90104 14.4784 3.83189C14.5475 3.76274 14.6296 3.70788 14.72 3.67046C14.8103 3.63303 14.9072 3.61377 15.005 3.61377H34.9218C35.1193 3.61377 35.3087 3.69223 35.4483 3.83189C35.588 3.97155 35.6665 4.16097 35.6665 4.35848C35.6665 4.45684 35.647 4.55423 35.6091 4.64502C35.5713 4.73581 35.5158 4.8182 35.446 4.88744C35.3761 4.95668 35.2932 5.0114 35.2021 5.04843C35.111 5.08546 35.0134 5.10407 34.9151 5.10319Z"
                                fill="#D80027"
                            />
                            <path
                                d="M11.6085 5.10276C10.9321 5.10188 10.2837 4.83279 9.80538 4.3545C9.32709 3.87622 9.05801 3.22778 9.05713 2.55138C9.05801 1.87499 9.32709 1.22654 9.80538 0.748259C10.2837 0.269974 10.9321 0.000883602 11.6085 0H38.3045C38.9809 0.000883602 39.6293 0.269974 40.1076 0.748259C40.5859 1.22654 40.855 1.87499 40.8559 2.55138C40.855 3.22778 40.5859 3.87622 40.1076 4.3545C39.6293 4.83279 38.9809 5.10188 38.3045 5.10276C38.2064 5.1032 38.1092 5.08427 38.0185 5.04704C37.9277 5.00981 37.8453 4.95502 37.7757 4.88583C37.7062 4.81663 37.6511 4.73438 37.6134 4.64381C37.5758 4.55324 37.5564 4.45613 37.5564 4.35805C37.5564 4.25997 37.5758 4.16286 37.6134 4.07229C37.6511 3.98172 37.7062 3.89948 37.7757 3.83028C37.8453 3.76109 37.9277 3.7063 38.0185 3.66907C38.1092 3.63184 38.2064 3.6129 38.3045 3.61334C38.5856 3.61246 38.8548 3.50019 39.0533 3.30113C39.2517 3.10207 39.3631 2.83246 39.3631 2.55138C39.3631 2.27031 39.2517 2.0007 39.0533 1.80164C38.8548 1.60258 38.5856 1.4903 38.3045 1.48942H11.6085C11.3274 1.4903 11.0582 1.60258 10.8598 1.80164C10.6613 2.0007 10.5499 2.27031 10.5499 2.55138C10.5499 2.83246 10.6613 3.10207 10.8598 3.30113C11.0582 3.50019 11.3274 3.61246 11.6085 3.61334C11.7066 3.6129 11.8038 3.63184 11.8945 3.66907C11.9853 3.7063 12.0678 3.76109 12.1373 3.83028C12.2068 3.89948 12.2619 3.98172 12.2995 4.07229C12.3372 4.16286 12.3566 4.25997 12.3566 4.35805C12.3566 4.45613 12.3372 4.55324 12.2995 4.64381C12.2619 4.73438 12.2068 4.81663 12.1373 4.88583C12.0678 4.95502 11.9853 5.00981 11.8945 5.04704C11.8038 5.08427 11.7066 5.1032 11.6085 5.10276Z"
                                fill="#D80027"
                            />
                            <path
                                d="M36.6552 21.8476C36.5932 21.8481 36.5314 21.8402 36.4715 21.8242C28.9126 19.8884 20.9878 19.8884 13.4289 21.8242C13.3342 21.8489 13.2355 21.8546 13.1386 21.841C13.0417 21.8275 12.9483 21.795 12.864 21.7454C12.7796 21.6957 12.7059 21.63 12.647 21.5518C12.588 21.4737 12.5451 21.3847 12.5206 21.2899C12.4719 21.0982 12.5011 20.895 12.6019 20.7247C12.7026 20.5545 12.8667 20.4311 13.0582 20.3816C20.8559 18.3845 29.0311 18.3845 36.8288 20.3816C36.9236 20.4061 37.0126 20.449 37.0907 20.5079C37.1689 20.5669 37.2346 20.6406 37.2843 20.725C37.3339 20.8093 37.3664 20.9027 37.3799 20.9996C37.3935 21.0965 37.3878 21.1952 37.3631 21.2899C37.3228 21.4477 37.2316 21.5879 37.1036 21.6887C36.9757 21.7895 36.8181 21.8454 36.6552 21.8476Z"
                                fill="#D80027"
                            />
                            <path
                                d="M19.1295 43.4838C19.0314 43.4838 18.9343 43.4644 18.8437 43.4268C18.7532 43.3892 18.6709 43.334 18.6017 43.2645C18.5325 43.195 18.4777 43.1125 18.4405 43.0218C18.4033 42.931 18.3843 42.8338 18.3848 42.7358V26.7061C18.3848 26.6083 18.404 26.5115 18.4415 26.4212C18.4789 26.3308 18.5337 26.2487 18.6029 26.1796C18.672 26.1104 18.7542 26.0555 18.8445 26.0181C18.9349 25.9807 19.0317 25.9614 19.1295 25.9614C19.2276 25.961 19.3248 25.9799 19.4155 26.0172C19.5062 26.0544 19.5887 26.1092 19.6582 26.1784C19.7277 26.2476 19.7829 26.3298 19.8205 26.4204C19.8582 26.511 19.8775 26.6081 19.8775 26.7061V42.7358C19.8775 42.9341 19.7987 43.1244 19.6584 43.2647C19.5181 43.405 19.3279 43.4838 19.1295 43.4838Z"
                                fill="#D80027"
                            />
                            <path
                                d="M24.9566 43.484C24.8585 43.484 24.7614 43.4646 24.6709 43.427C24.5803 43.3893 24.4981 43.3342 24.4289 43.2647C24.3597 43.1952 24.3049 43.1127 24.2676 43.022C24.2304 42.9312 24.2115 42.834 24.2119 42.7359V25.9249C24.2119 25.7274 24.2904 25.538 24.43 25.3983C24.5697 25.2586 24.7591 25.1802 24.9566 25.1802C25.1541 25.1802 25.3436 25.2586 25.4832 25.3983C25.6229 25.538 25.7013 25.7274 25.7013 25.9249V42.7359C25.7018 42.834 25.6828 42.9312 25.6456 43.022C25.6084 43.1127 25.5536 43.1952 25.4844 43.2647C25.4152 43.3342 25.333 43.3893 25.2424 43.427C25.1518 43.4646 25.0547 43.484 24.9566 43.484Z"
                                fill="#D80027"
                            />
                            <path
                                d="M30.7842 43.4838C30.5858 43.4838 30.3955 43.405 30.2552 43.2647C30.1149 43.1244 30.0361 42.9341 30.0361 42.7358V26.7061C30.0361 26.6081 30.0555 26.511 30.0931 26.4204C30.1308 26.3298 30.1859 26.2476 30.2554 26.1784C30.3249 26.1092 30.4074 26.0544 30.4982 26.0172C30.5889 25.9799 30.6861 25.961 30.7842 25.9614C30.9817 25.9614 31.1711 26.0399 31.3108 26.1796C31.4504 26.3192 31.5289 26.5086 31.5289 26.7061V42.7358C31.5293 42.8338 31.5104 42.931 31.4732 43.0218C31.4359 43.1125 31.3811 43.195 31.3119 43.2645C31.2428 43.334 31.1605 43.3892 31.0699 43.4268C30.9794 43.4644 30.8823 43.4838 30.7842 43.4838Z"
                                fill="#D80027"
                            />
                            <path
                                d="M48.3565 43.4843H35.9102V24.7831C28.7122 23.0366 21.201 23.0366 14.0031 24.7831V43.4843H1.55672C1.45864 43.4843 1.36155 43.4649 1.27098 43.4273C1.18041 43.3897 1.09815 43.3345 1.02895 43.265C0.959756 43.1955 0.904974 43.113 0.867746 43.0223C0.830517 42.9315 0.811579 42.8343 0.812019 42.7363C0.812019 42.5388 0.890474 42.3493 1.03013 42.2097C1.16979 42.07 1.35921 41.9916 1.55672 41.9916H12.5103V23.6243L13.0713 23.4807C20.869 21.4836 29.0442 21.4836 36.8419 23.4807L37.4029 23.6243V41.9916H48.3565C48.554 41.9916 48.7434 42.07 48.8831 42.2097C49.0228 42.3493 49.1012 42.5388 49.1012 42.7363C49.1017 42.8343 49.0827 42.9315 49.0455 43.0223C49.0083 43.113 48.9535 43.1955 48.8843 43.265C48.8151 43.3345 48.7328 43.3897 48.6423 43.4273C48.5517 43.4649 48.4546 43.4843 48.3565 43.4843Z"
                                fill="#D80027"
                            />
                        </svg>
                        <p className="font-[Enriqueta]  text-[36.91899108886719px] text-left text-[#373737]">
                            Legal EPROF
                        </p>
                    </div>
                    {/* gap-[30px] */}
                    <div className="flex  flex-col lg:flex-row  justify-evenly lg:justify-center items-center  relative w-[80vw] my-10  gap-[50px] lg:gap-24  ">
                        <a
                            href="/"
                            className=" text-lg text-left text-[#7b7b7b]"
                        >
                            Inicio
                        </a>

                        <a
                            href="/howitworks"
                            className=" text-lg text-left text-[#7b7b7b]"
                        >
                            ¿Cómo Funciona?
                        </a>
                        <a
                            href="/professionals"
                            className=" text-lg text-left text-[#7b7b7b]"
                        >
                            Profesionales
                        </a>
                        <a
                            href="/blog"
                            className=" text-lg text-left text-[#7b7b7b]"
                        >
                            Blog
                        </a>
                        <a
                            href="/contact-us"
                            className=" text-lg text-left text-[#7b7b7b]"
                        >
                            Contacto
                        </a>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start lg:self-stretch  relative lg:gap-[10vw] px-3 text-right lg:text-center my-2 ">
                    <p className="hidden lg:block text-lg text-left text-[#373737]">
                        © 2023 EProf
                    </p>
                    <div className="flex flex-col lg:flex-row justify-center items-center flex-grow relative gap-[17px] lg:gap-[87px]">
                        <a
                            href="/privacy"
                            className=" text-lg text-left text-[#d80027]"
                        >
                            Política de privacidad
                        </a>
                        <a
                            href="/privacy"
                            className=" text-lg text-left text-[#d80027]"
                        >
                            Preguntas frecuentes
                        </a>
                    </div>
                    {/* w-[151px] */}
                    <div className="flex justify-evenly   items-start w-full lg:w-36 relative gap-[7px]  my-10 lg:m-0">
                        <Socials />
                    </div>
                    <p className="block lg:hidden text-lg text-left text-[#373737]">
                        © 2023 EProf
                    </p>
                </div>
            </div>
        ) : null
    );
}

export default Footer;
