const COUNTRIES = [
    "Seleccione una nacionalidad",
    "Costa Rica",
    "El Salvador",
    "Guatemala",
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia Herzegovina",
    "Botswana",
    "Brazil",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Cape Verde",
    "Cayman Islands",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Cote D Ivoire",
    "Croatia",
    "Cruise Ship",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Polynesia",
    "French West Indies",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kuwait",
    "Kyrgyz Republic",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Norway",
    "Oman",
    "Pakistan",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Pierre Miquelon",
    "Samoa",
    "San Marino",
    "Satellite",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "South Africa",
    "South Korea",
    "Spain",
    "Sri Lanka",
    "St Kitts Nevis",
    "St Lucia",
    "St Vincent",
    "St. Lucia",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor L'Este",
    "Togo",
    "Tonga",
    "Trinidad Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks Caicos",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "Uruguay",
    "Uzbekistan",
    "Venezuela",
    "Vietnam",
    "Virgin Islands (US)",
    "Yemen",
    "Zambia",
    "Zimbabwe"
];

const SECTORS = ["Derecho", "Público", "Privado"];

const CATEGORIESS = {
    [SECTORS[1]]: [
        "DISPONIBLES",
        "CONTRATACION URGENTE",
        "CONTRATACION RECOMENDADA",
        "ASESORÍA URGENTE",
        "ASESORÍA RECOMENDADA",
        "NO DISPONIBLES"
    ],
   
};

const CATEGORIES = {
    [SECTORS[1]]: [
        "Derecho penal - Derecho Procesal Penal",
        "Derecho Administrativo",
        "Derecho Constitucional",
        "Derecho Laboral"
    ],
    [SECTORS[2]]: [
        "Derecho Notarial",
        "Derecho Civil - Derecho Procesal Civil",
        `Derecho Civil Sustantivo `,
        `Derecho Mercantil `
    ]
};


const EXCLUSIVE_LATIN_COUNTRIES = {
    PAIS:"País",
    COSTA_RICA: "Costa Rica",
    EL_SALVADOR: "El Salvador",
    GUATEMALA: "Guatemala"
};

const ALL_DEPARTMENTS = {
    [EXCLUSIVE_LATIN_COUNTRIES.GUATEMALA]: [
        "Seleccione un departamento",
        "Departamentos",
        "Alta Verapaz",
        "Baja Verapaz",
        "Chimaltenango",
        "Chiquimula",
        "El Progreso",
        "Escuintla",
        "Guatemala",
        "Huehuetenango",
        "Izabal",
        "Jalapa",
        "Jutiapa",
        "Peten",
        "Quetzaltenango",
        "Quiche",
        "Retalhuleu",
        "Sacatepequez",
        "San Marcos",
        "Santa Rosa",
        "Solola",
        "Suchitepequez",
        "Totonicapan",
        "Zacapa"
    ],
    [EXCLUSIVE_LATIN_COUNTRIES.EL_SALVADOR]: [
        "Seleccione un departamento",
        "Ahuachapán",
        "Cabañas",
        "Chalatenango",
        "Cuscatlán",
        "La Libertad",
        "La Paz",
        "La Unión",
        "Morazán",
        "San Miguel",
        "San Salvador",
        "San Vicente",
        "Santa Ana",
        "Sonsonate",
        "Usulután"
    ]
};

const ALL_MUNICIPALITIES = {
    [EXCLUSIVE_LATIN_COUNTRIES.GUATEMALA]: {
        Municipalidades: ["Municipalidades"],
        "Alta Verapaz": [
            "Seleccione un municipio",
            "Chahal",
            "Chisec",
            "Coban",
            "Fray Bartolome De Las Casas",
            "Panzos",
            "Raxruha",
            "San Agustin Lanquin",
            "San Cristobal Verapaz",
            "San Juan Chamelco",
            "San Miguel Tucuru",
            "San Pedro Carcha",
            "Santa Catalina La Tinta",
            "Santa Cruz Verapaz",
            "Santa Maria Cahabon",
            "Senahu",
            "Tactic",
            "Tamahu"
        ],
        "Baja Verapaz": [
            "Seleccione un municipio",
            "Cubulco",
            "Granados",
            "Purulha",
            "Rabinal",
            "Salama",
            "San Jeronimo",
            "San Miguel Chicaj",
            "Santa Cruz El Chol"
        ],
        Chimaltenango: [
            "Seleccione un municipio",
            "Acatenango",
            "Chimaltenango",
            "El Tejar",
            "Parramos",
            "Patzicia",
            "Patzun",
            "San Andres Itzapa",
            "San Jose Poaquil",
            "San Juan Comalapa",
            "San Martin Jilotepeque",
            "San Miguel Pochuta",
            "San Pedro Yepocapa",
            "Santa Apolonioa",
            "Santa Cruz Balanya",
            "Tecpan",
            "Zaragoza"
        ],
        Chiquimula: [
            "Seleccione un municipio",
            "Camotan",
            "Chiquimula",
            "Concepcion Las Minas",
            "Esquipulas",
            "Ipala",
            "Jocotan",
            "Olopa",
            "Quezaltepeque",
            "San Jacinto",
            "San Jose La Arada",
            "San Juan Hermita"
        ],
        "El Progreso": [
            "Seleccione un municipio",
            "El Jicaro",
            "Guastatoya",
            "Morazan",
            "San Agustin Acasaguastlan",
            "San Antonio La Paz",
            "San Cristobal Acasaguastlan",
            "Sanarate",
            "Sansare"
        ],
        Escuintla: [
            "Seleccione un municipio",
            "Escuintla",
            "Guaganazapa",
            "Iztapa",
            "La Democracia",
            "La Gomera",
            "Masagua",
            "Nueva Concepcion",
            "Palin",
            "San Jose",
            "San Vicente Pacaya",
            "Santa Lucia Cotzumalguapa",
            "Siquinala",
            "Tiquisate"
        ],
        Guatemala: [
            "Seleccione un municipio",
            "Amatitlan",
            "Chinautla",
            "Chuarrancho",
            "Fraijanes",
            "Guatemala",
            "Mixco",
            "Palencia",
            "San Jose Del Golfo",
            "San Jose Pinula",
            "San Juan Sacatepequez",
            "San Miguel Petapa",
            "San Pedro Ayampuc",
            "San Pedro Sacatepequez",
            "San Raymundo",
            "Santa Catarina Pinula",
            "Villa Canales",
            "Villa Nueva"
        ],
        Huehuetenango: [
            "Seleccione un municipio",
            "Aguacatan",
            "Chiantla",
            "Colotenango",
            "Concepcion Huista",
            "Cuilco",
            "Huehuetenango",
            "Ixtahuacan",
            "Jacaltenango",
            "La Democracia",
            "La Libertad",
            "Malacatancito",
            "Nenton",
            "San Antonio Huista",
            "San Gaspar Ixchil",
            "San Juan Atitlan",
            "San Juan Ixcoy",
            "San Mateo Ixtatan",
            "San Miguel Acatan",
            "San Pedro Necta",
            "San Rafael La Independencia",
            "San Rafael Petzal",
            "San Sebastian Coatan",
            "San Sebastian Huehuetenango",
            "Santa Ana Huista",
            "Santa Barbara",
            "Santa Cruz Barillas",
            "Santa Eulalia",
            "Santiago Chimaltenango",
            "Soloma",
            "Tectitan",
            "Todos Santos Cuchumatan"
        ],
        Izabal: [
            "Seleccione un municipio",
            "El Estor",
            "Livingston",
            "Los Amates",
            "Morales",
            "Puerto Barrios"
        ],
        Jalapa: [
            "Seleccione un municipio",
            "Jalapa",
            "Mataquescuintla",
            "Monjas",
            "San Carlos Alzatate",
            "San Luis Jilotepeque",
            "San Manuel Chaparron",
            "San Pedro Pinula"
        ],
        Jutiapa: [
            "Seleccione un municipio",
            "Agua Blanca",
            "Asuncion Mita",
            "Atescatempa",
            "Comapa",
            "Conguaco",
            "El Adelanto",
            "El Progreso",
            "Jalpatagua",
            "Jerez",
            "Jutiapa",
            "Moyuta",
            "Pasaco",
            "Quesada",
            "Santa Catarina Mita",
            "Yupiltepeque",
            "Zapotitlan"
        ],
        Peten: [
            "Seleccione un municipio",
            "Dolores",
            "Flores",
            "La Libertad",
            "Melchor De Mencos",
            "Poptun",
            "San Andres",
            "San Benito",
            "San Francisco",
            "San Jose",
            "San Luis",
            "Santa Ana",
            "Sayaxche"
        ],
        Quetzaltenango: [
            "Seleccione un municipio",
            "Almolonga",
            "Cabrican",
            "Cajola",
            "Cantel",
            "Coatepeque",
            "Colomba Costa Cuca",
            "Concepcion Chiquirichapa",
            "El Palmar",
            "Flores Costa Cuca",
            "Genova",
            "Huitan",
            "La Esperanza",
            "Palestina De Los Altos",
            "Quetzaltenango",
            "Salcaja",
            "San Carlos Sija",
            "San Francisco La Union",
            "San Juan Olintepeque",
            "San Juan Ostuncalco",
            "San Martin Sacatepequez",
            "San Mateo",
            "San Miguel Siguila",
            "Sibilia",
            "Zunil"
        ],

        Quiche: [
            "Seleccione un municipio",
            "Canilla",
            "Chajul",
            "Chicaman",
            "Chiche",
            "Chinique",
            "Cunen",
            "Ixcan",
            "Pachalum",
            "Patzite",
            "Sacapulas",
            "San Andres Sajcabaja",
            "San Antonio Ilotenango",
            "San Bartolome Jocotenango",
            "San Juan Cotzal",
            "San Pedro Jocopilas",
            "Santa Cruz Del Quiche",
            "Santa Maria Joyabaj",
            "Santa Maria Nebaj",
            "Santo Tomas Chichicastenango",
            "Uspantan",
            "Zacualpa"
        ],

        Retalhuleu: [
            "Seleccione un municipio",
            "Champerico",
            "El Asintal",
            "Nuevo San Carlos",
            "Retalhuleu",
            "San Andres Villa Seca",
            "San Felipe",
            "San Martin Zapotitlan",
            "San Sebastian",
            "Santa Cruz Mulua"
        ],

        Sacatepequez: [
            "Seleccione un municipio",
            "Antigua Guatemala",
            "Ciudad Vieja",
            "Jocotenango",
            "Magdalena Milpas Altas",
            "Pastores",
            "San Antonio Aguas Calientes",
            "San Bartolome Milpas Altas",
            "San Juan Alotenango",
            "San Lucas Sacatepequez",
            "San Miguel Duenas",
            "Santa Catarina Barahona",
            "Santa Lucia Milpas Altas",
            "Santa Maria De Jesus",
            "Santiago Sacatepequez",
            "Santo Domingo Xenacoj",
            "Sumpango"
        ],

        "San Marcos": [
            "Seleccione un municipio",
            "Ayutla",
            "Catarina",
            "Comitancillo",
            "Concepcion Tutuapa",
            "El Quetzal",
            "El Rodeo",
            "El Tumblador",
            "Esquipulas Palo Gordo",
            "Ixchiguan",
            "La Blanca",
            "La Reforma",
            "Malacatan",
            "Nuevo Progreso",
            "Ocos",
            "Pajapita",
            "Rio Blanco",
            "San Antonio Sacatepequez",
            "San Cristobal Cucho",
            "San Jose Ojetenam",
            "San Lorenzo",
            "San Marcos",
            "San Miguel Ixtahuacan",
            "San Pablo",
            "San Pedro Sacatepequez",
            "San Rafael Pie De La Cuesta",
            "Sibinal",
            "Sipacapa",
            "Tacana",
            "Tajumulco",
            "Tejutla"
        ],

        "Santa Rosa": [
            "Seleccione un municipio",
            "Barberena",
            "Casillas Santa Rosa",
            "Chiquimulilla",
            "Cuilapa",
            "Guazacapan",
            "Nueva Santa Rosa",
            "Oratorio",
            "Pueblo Nuevo Vinas",
            "San Juan Tecuaco",
            "San Rafael Las Flores",
            "Santa Cruz Naranjo",
            "Santa Maria Ixhuatan",
            "Santa Rosa De Lima",
            "Taxisco"
        ],

        Solola: [
            "Seleccione un municipio",
            "Concepcion",
            "Nahuala",
            "Panajachel",
            "San Andres Semetabaj",
            "San Antonio Palopo",
            "San Jose Chacaya",
            "San Juan La Laguna",
            "San Lucas Toliman",
            "San Marcos La Laguna",
            "San Pablo La Laguna",
            "San Pedro La Laguna",
            "Santa Catarina Ixtahuacan",
            "Santa Catarina Palopo",
            "Santa Clara La Laguna",
            "Santa Cruz La Laguna",
            "Santa Lucia Utatlan",
            "Santa Maria Visitacion",
            "Santiago Atitlan",
            "Solola"
        ],

        Suchitepequez: [
            "Seleccione un municipio",
            "Chicacao",
            "Cuyotenango",
            "Mazatenango",
            "Patulul",
            "Pueblo Nuevo",
            "Rio Bravo",
            "Samayac",
            "San Antonio Suchitepequez",
            "San Bernardino",
            "San Francisco Zapotitlan",
            "San Gabriel",
            "San Jose El Idolo",
            "San Juan Bautista",
            "San Lorenzo",
            "San Miguel Panan",
            "San Pablo Jocopilas",
            "Santa Barbara",
            "Santo Domingo Suchitepequez",
            "Santo Tomas La Union",
            "Zunilito"
        ],

        Totonicapan: [
            "Seleccione un municipio",
            "Momostenango",
            "San Andres Xecul",
            "San Bartolo",
            "San Cristobal Totonicapan",
            "San Francisco El Alto",
            "Santa Lucia La Reforma",
            "Santa Maria Chiquimula",
            "Totonicapan"
        ],

        Zacapa: [
            "Seleccione un municipio",
            "Cabanas",
            "Estanzuela",
            "Gualan",
            "Huite",
            "La Union",
            "Rio Hondo",
            "San Diego",
            "San Jorge",
            "Teculutan",
            "Usumatlan",
            "Zacapa"
        ]
    },
    [EXCLUSIVE_LATIN_COUNTRIES.EL_SALVADOR]: {
        Ahuachapán: [
            "Seleccione un municipio",
            "Ahuachapán",
            "Atiquizaya",
            "Concepción de Ataco",
            "El Refugio",
            "Guaymango",
            "Jujutla",
            "San Francisco Menéndez",
            "San Lorenzo",
            "Santa Ana",
            "Tacuba",
            "Turín"
        ],
        Cabañas: [
            "Seleccione un municipio",
            "Cinquera",
            "Dolores",
            "Guacotecti",
            "Ilobasco",
            "Jutiapa",
            "San Isidro",
            "Sensuntepeque",
            "Tejutla",
            "Victoria"
        ],
        Chalatenango: [
            "Seleccione un municipio",
            "Agua Caliente",
            "Arcatao",
            "Chalatenango",
            "Comapa",
            "Dulce Nombre de María",
            "El Carrizal",
            "El Paraíso",
            "La Laguna",
            "Las Vueltas",
            "Nueva Concepción",
            "Ojos de Agua",
            "San Antonio de la Cruz",
            "San Francisco Lempa",
            "San José Las Flores",
            "San Luis del Carmen",
            "Santa Rita",
            "Tejutla"
        ],
        Cuscatlán: [
            "Seleccione un municipio",
            "Candelaria",
            "Chalchuapa",
            "Cojutepeque",
            "El Carmen",
            "El Rosario",
            "Monte San Juan",
            "Oratorio de Concepción",
            "San Bartolomé Perulapía",
            "San Cristóbal Cuscatlán",
            "San José Guayabal",
            "San Pedro Perulapán",
            "Santa Cruz Analquito",
            "Santa María",
            "Suchitoto"
        ],
        "La Libertad": [
            "Seleccione un municipio",
            "Antiguo Cuscatlán",
            "Chiltiupán",
            "Ciudad Arce",
            "Colón",
            "Comasagua",
            "Huizúcar",
            "Jayaque",
            "La Libertad",
            "Metalío",
            "Nueva San Salvador",
            "Quezaltepeque",
            "Sacacoyo",
            "San José Villanueva",
            "Santa Tecla",
            "Tapalpa",
            "Teotepeque"
        ],
        "La Paz": [
            "Seleccione un municipio",
            "Cacaopera",
            "Chalatenango",
            "El Rosario",
            "Entique Izalco",
            "Jerusalén",
            "La Paz",
            "Olocuilta",
            "San Antonio Masahuat",
            "San Emigdio",
            "San Francisco Chinameca",
            "San Juan Nonualco",
            "San Pedro Masahuat",
            "Santiago Nonualco",
            "Tapastepeque",
            "Zacatecoluca"
        ],
        "La Unión": [
            "Seleccione un municipio",
            "Anamorós",
            "Bolívar",
            "Conchagua",
            "El Carmen",
            "El Sauce",
            "Intipucá",
            "La Unión",
            "Lislique",
            "Meanguera del Golfo",
            "Nueva Esparta",
            "Pasaquina",
            "San Alejo",
            "San José La Fuente",
            "Santa Rosa de Lima",
            "Tecpán",
            "Yayantique"
        ],
        Morazán: [
            "Seleccione un municipio",
            "Arambala",
            "Cacaopera",
            "Chalatenango",
            "Chilanga",
            "Corinto",
            "Delicias de Concepción",
            "El Divisadero",
            "El Rosario",
            "Gualococti",
            "Guatajiagua",
            "Joateca",
            "Jocoro",
            "Lolotique",
            "Meanguera",
            "Morazán",
            "Osicala",
            "Perquín",
            "San Carlos",
            "San Fernando",
            "San Francisco Gotera",
            "San Isidro",
            "San Simón",
            "Sensembra",
            "Sociedad",
            "Soledad",
            "Torola",
            "Yoloaiquín"
        ],
        "San Miguel": [
            "Seleccione un municipio",
            "San Miguel",
            "Carolina",
            "Chapeltique",
            "Chinameca",
            "Chirilagua",
            "Ciudad Barrios",
            "Comacarán",
            "El Tránsito",
            "Lolotique",
            "Moncagua",
            "Nueva Guadalupe",
            "Quelepa",
            "San Antonio del Mosco",
            "San Gerardo",
            "San Jorge",
            "San Luis de la Reina",
            "San Rafael Oriente",
            "Sesori",
            "Uluazapa"
        ],
        "San Salvador": [
            "Seleccione un municipio",
            "San Salvador",
            "Aguilares",
            "Apopa",
            "Ayutuxtepeque",
            "Cuscatancingo",
            "Delgado",
            "Soyapango",
            "El Paisnal",
            "Guazapa",
            "Ilopango",
            "Jiquilisco",
            "Malacatiupan",
            "Mejicanos",
            "Nejapa",
            "Panchimalco",
            "Rosario de Mora",
            "San Marcos",
            "San Martín",
            "Santiago Texacuangos",
            "Santo Tomás",
            "Tonacatepeque"
        ],
        "San Vicente": [
            "Seleccione un municipio",
            "San Vicente",
            "Apastepeque",
            "Guadalupe",
            "San Cayetano Istepeque",
            "San Esteban Catarina",
            "San Ildefonso",
            "San Lorenzo",
            "San Sebastián",
            "Santa Clara",
            "Tecoluca",
            "Verapaz"
        ],
        "Santa Ana": [
            "Seleccione un municipio",
            "Santa Ana",
            "Candelaria de la Frontera",
            "Chalchuapa",
            "Coatepeque",
            "El Congo",
            "Metapán",
            "San Antonio Pajonal",
            "San Sebastián Salitrillo",
            "Santa Rosa Guachipilín",
            "Santiago de la Frontera",
            "Texistepeque"
        ],
        Sonsonate: [
            "Seleccione un municipio",
            "Sonsonate",
            "Acajutla",
            "Armenia",
            "Caluco",
            "Cerritos",
            "Izalco",
            "Juayúa",
            "Nahuizalco",
            "Nahuizalco",
            "Salcoatitán",
            "San Antonio del Monte",
            "San Julián",
            "Santa Catarina Masahuat",
            "Sonzacate"
        ],
        Usulután: [
            "Seleccione un municipio",
            "Usulután",
            "Alegría",
            "Berlin",
            "California",
            "Chalchuapa",
            "El Triunfo",
            "Ereguayquín",
            "Estanzuelas",
            "Jiquilisco",
            "Jucuapa",
            "Mercedes Umaña",
            "Nueva Granada",
            "Ozatlán",
            "Puerto El Triunfo",
            "San Agustín",
            "San Buenaventura",
            "San Francisco Javier",
            "Santa Elena",
            "Santa María",
            "Tecapán"
        ]
    }
};

const DEPARTMENTS = [
    "Alta Verapaz",
    "Baja Verapaz",
    "Chimaltenango",
    "Chiquimula",
    "El Progreso",
    "Escuintla",
    "Guatemala",
    "Huehuetenango",
    "Izabal",
    "Jalapa",
    "Jutiapa",
    "Peten",
    "Quetzaltenango",
    "Quiche",
    "Retalhuleu",
    "Sacatepequez",
    "San Marcos",
    "Santa Rosa",
    "Solola",
    "Suchitepequez",
    "Totonicapan",
    "Zacapa"
];

const MUNICIPALITIES = {
    Municipalidades: ["Municipalidades"],
    "Alta Verapaz": [
        "Chahal",
        "Chisec",
        "Coban",
        "Fray Bartolome De Las Casas",
        "Panzos",
        "Raxruha",
        "San Agustin Lanquin",
        "San Cristobal Verapaz",
        "San Juan Chamelco",
        "San Miguel Tucuru",
        "San Pedro Carcha",
        "Santa Catalina La Tinta",
        "Santa Cruz Verapaz",
        "Santa Maria Cahabon",
        "Senahu",
        "Tactic",
        "Tamahu"
    ],
    "Baja Verapaz": [
        "Cubulco",
        "Granados",
        "Purulha",
        "Rabinal",
        "Salama",
        "San Jeronimo",
        "San Miguel Chicaj",
        "Santa Cruz El Chol"
    ],
    Chimaltenango: [
        "Acatenango",
        "Chimaltenango",
        "El Tejar",
        "Parramos",
        "Patzicia",
        "Patzun",
        "San Andres Itzapa",
        "San Jose Poaquil",
        "San Juan Comalapa",
        "San Martin Jilotepeque",
        "San Miguel Pochuta",
        "San Pedro Yepocapa",
        "Santa Apolonioa",
        "Santa Cruz Balanya",
        "Tecpan",
        "Zaragoza"
    ],
    Chiquimula: [
        "Camotan",
        "Chiquimula",
        "Concepcion Las Minas",
        "Esquipulas",
        "Ipala",
        "Jocotan",
        "Olopa",
        "Quezaltepeque",
        "San Jacinto",
        "San Jose La Arada",
        "San Juan Hermita"
    ],
    "El Progreso": [
        "El Jicaro",
        "Guastatoya",
        "Morazan",
        "San Agustin Acasaguastlan",
        "San Antonio La Paz",
        "San Cristobal Acasaguastlan",
        "Sanarate",
        "Sansare"
    ],
    Escuintla: [
        "Escuintla",
        "Guaganazapa",
        "Iztapa",
        "La Democracia",
        "La Gomera",
        "Masagua",
        "Nueva Concepcion",
        "Palin",
        "San Jose",
        "San Vicente Pacaya",
        "Santa Lucia Cotzumalguapa",
        "Siquinala",
        "Tiquisate"
    ],
    Guatemala: [
        "Amatitlan",
        "Chinautla",
        "Chuarrancho",
        "Fraijanes",
        "Guatemala",
        "Mixco",
        "Palencia",
        "San Jose Del Golfo",
        "San Jose Pinula",
        "San Juan Sacatepequez",
        "San Miguel Petapa",
        "San Pedro Ayampuc",
        "San Pedro Sacatepequez",
        "San Raymundo",
        "Santa Catarina Pinula",
        "Villa Canales",
        "Villa Nueva"
    ],
    Huehuetenango: [
        "Aguacatan",
        "Chiantla",
        "Colotenango",
        "Concepcion Huista",
        "Cuilco",
        "Huehuetenango",
        "Ixtahuacan",
        "Jacaltenango",
        "La Democracia",
        "La Libertad",
        "Malacatancito",
        "Nenton",
        "San Antonio Huista",
        "San Gaspar Ixchil",
        "San Juan Atitlan",
        "San Juan Ixcoy",
        "San Mateo Ixtatan",
        "San Miguel Acatan",
        "San Pedro Necta",
        "San Rafael La Independencia",
        "San Rafael Petzal",
        "San Sebastian Coatan",
        "San Sebastian Huehuetenango",
        "Santa Ana Huista",
        "Santa Barbara",
        "Santa Cruz Barillas",
        "Santa Eulalia",
        "Santiago Chimaltenango",
        "Soloma",
        "Tectitan",
        "Todos Santos Cuchumatan"
    ],
    Izabal: [
        "El Estor",
        "Livingston",
        "Los Amates",
        "Morales",
        "Puerto Barrios"
    ],
    Jalapa: [
        "Jalapa",
        "Mataquescuintla",
        "Monjas",
        "San Carlos Alzatate",
        "San Luis Jilotepeque",
        "San Manuel Chaparron",
        "San Pedro Pinula"
    ],
    Jutiapa: [
        "Agua Blanca",
        "Asuncion Mita",
        "Atescatempa",
        "Comapa",
        "Conguaco",
        "El Adelanto",
        "El Progreso",
        "Jalpatagua",
        "Jerez",
        "Jutiapa",
        "Moyuta",
        "Pasaco",
        "Quesada",
        "Santa Catarina Mita",
        "Yupiltepeque",
        "Zapotitlan"
    ],
    Peten: [
        "Dolores",
        "Flores",
        "La Libertad",
        "Melchor De Mencos",
        "Poptun",
        "San Andres",
        "San Benito",
        "San Francisco",
        "San Jose",
        "San Luis",
        "Santa Ana",
        "Sayaxche"
    ],
    Quetzaltenango: [
        "Almolonga",
        "Cabrican",
        "Cajola",
        "Cantel",
        "Coatepeque",
        "Colomba Costa Cuca",
        "Concepcion Chiquirichapa",
        "El Palmar",
        "Flores Costa Cuca",
        "Genova",
        "Huitan",
        "La Esperanza",
        "Palestina De Los Altos",
        "Quetzaltenango",
        "Salcaja",
        "San Carlos Sija",
        "San Francisco La Union",
        "San Juan Olintepeque",
        "San Juan Ostuncalco",
        "San Martin Sacatepequez",
        "San Mateo",
        "San Miguel Siguila",
        "Sibilia",
        "Zunil"
    ],

    Quiche: [
        "Canilla",
        "Chajul",
        "Chicaman",
        "Chiche",
        "Chinique",
        "Cunen",
        "Ixcan",
        "Pachalum",
        "Patzite",
        "Sacapulas",
        "San Andres Sajcabaja",
        "San Antonio Ilotenango",
        "San Bartolome Jocotenango",
        "San Juan Cotzal",
        "San Pedro Jocopilas",
        "Santa Cruz Del Quiche",
        "Santa Maria Joyabaj",
        "Santa Maria Nebaj",
        "Santo Tomas Chichicastenango",
        "Uspantan",
        "Zacualpa"
    ],

    Retalhuleu: [
        "Champerico",
        "El Asintal",
        "Nuevo San Carlos",
        "Retalhuleu",
        "San Andres Villa Seca",
        "San Felipe",
        "San Martin Zapotitlan",
        "San Sebastian",
        "Santa Cruz Mulua"
    ],

    Sacatepequez: [
        "Antigua Guatemala",
        "Ciudad Vieja",
        "Jocotenango",
        "Magdalena Milpas Altas",
        "Pastores",
        "San Antonio Aguas Calientes",
        "San Bartolome Milpas Altas",
        "San Juan Alotenango",
        "San Lucas Sacatepequez",
        "San Miguel Duenas",
        "Santa Catarina Barahona",
        "Santa Lucia Milpas Altas",
        "Santa Maria De Jesus",
        "Santiago Sacatepequez",
        "Santo Domingo Xenacoj",
        "Sumpango"
    ],

    "San Marcos": [
        "Ayutla",
        "Catarina",
        "Comitancillo",
        "Concepcion Tutuapa",
        "El Quetzal",
        "El Rodeo",
        "El Tumblador",
        "Esquipulas Palo Gordo",
        "Ixchiguan",
        "La Blanca",
        "La Reforma",
        "Malacatan",
        "Nuevo Progreso",
        "Ocos",
        "Pajapita",
        "Rio Blanco",
        "San Antonio Sacatepequez",
        "San Cristobal Cucho",
        "San Jose Ojetenam",
        "San Lorenzo",
        "San Marcos",
        "San Miguel Ixtahuacan",
        "San Pablo",
        "San Pedro Sacatepequez",
        "San Rafael Pie De La Cuesta",
        "Sibinal",
        "Sipacapa",
        "Tacana",
        "Tajumulco",
        "Tejutla"
    ],

    "Santa Rosa": [
        "Barberena",
        "Casillas Santa Rosa",
        "Chiquimulilla",
        "Cuilapa",
        "Guazacapan",
        "Nueva Santa Rosa",
        "Oratorio",
        "Pueblo Nuevo Vinas",
        "San Juan Tecuaco",
        "San Rafael Las Flores",
        "Santa Cruz Naranjo",
        "Santa Maria Ixhuatan",
        "Santa Rosa De Lima",
        "Taxisco"
    ],

    Solola: [
        "Concepcion",
        "Nahuala",
        "Panajachel",
        "San Andres Semetabaj",
        "San Antonio Palopo",
        "San Jose Chacaya",
        "San Juan La Laguna",
        "San Lucas Toliman",
        "San Marcos La Laguna",
        "San Pablo La Laguna",
        "San Pedro La Laguna",
        "Santa Catarina Ixtahuacan",
        "Santa Catarina Palopo",
        "Santa Clara La Laguna",
        "Santa Cruz La Laguna",
        "Santa Lucia Utatlan",
        "Santa Maria Visitacion",
        "Santiago Atitlan",
        "Solola"
    ],

    Suchitepequez: [
        "Chicacao",
        "Cuyotenango",
        "Mazatenango",
        "Patulul",
        "Pueblo Nuevo",
        "Rio Bravo",
        "Samayac",
        "San Antonio Suchitepequez",
        "San Bernardino",
        "San Francisco Zapotitlan",
        "San Gabriel",
        "San Jose El Idolo",
        "San Juan Bautista",
        "San Lorenzo",
        "San Miguel Panan",
        "San Pablo Jocopilas",
        "Santa Barbara",
        "Santo Domingo Suchitepequez",
        "Santo Tomas La Union",
        "Zunilito"
    ],

    Totonicapan: [
        "Momostenango",
        "San Andres Xecul",
        "San Bartolo",
        "San Cristobal Totonicapan",
        "San Francisco El Alto",
        "Santa Lucia La Reforma",
        "Santa Maria Chiquimula",
        "Totonicapan"
    ],

    Zacapa: [
        "Cabanas",
        "Estanzuela",
        "Gualan",
        "Huite",
        "La Union",
        "Rio Hondo",
        "San Diego",
        "San Jorge",
        "Teculutan",
        "Usumatlan",
        "Zacapa"
    ]
};

const LATIN_COUNTRIES = [
    "País",
    "Argentina",
    "Bolivia",
    "Brazil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Dominican Republic",
    "Ecuador",
    "El Salvador",
    "Guatemala",
    "Haiti",
    "Honduras",
    "Jamaica",
    "Mexico",
    "Nicaragua",
    "Panama",
    "Paraguay",
    "Peru",
    "Puerto Rico",
    "Uruguay",
    "Venezuela"
];

const PROVINCES = [
    "Seleccione una Provincia",
    "San José",
    "Alajuela",
    "Cartago",
    "Heredia",
    "Guanacaste",
    "Puntarenas",
    "Limón"
];

const CANTONS = [
    "Seleccione un Cantón",
    "Acosta",
    "Alajuela",
    "Alajuelita",
    "Alvarado",
    "Aserrí",
    "Atenas",
    "Bagaces",
    "Barva",
    "Belén",
    "Buenos Aires",
    "Cañas",
    "Carrillo",
    "Cartago",
    "Corredores",
    "Coto Brus",
    "Curridabat",
    "Desamparados",
    "Dota",
    "El Guarco",
    "Escazú",
    "Esparza",
    "Flores",
    "Garabito",
    "Golfito",
    "Goicoechea",
    "Grecia",
    "Guácimo",
    "Guatuso",
    "Heredia",
    "Hojancha",
    "Jiménez",
    "La Unión",
    "Liberia",
    "Limón",
    "Los Chiles",
    "Matina",
    "Montes de Oca",
    "Montes de Oro",
    "Mora",
    "Moravia",
    "Naranjo",
    "Nicoya",
    "Orotina",
    "Osa",
    "Palmares",
    "Paraíso",
    "Pérez Zeledón",
    "Poás",
    "Pococí",
    "Puriscal",
    "Puntarenas",
    "Quepos",
    "San Carlos",
    "San Isidro",
    "San José",
    "San Mateo",
    "San Pablo",
    "San Rafael",
    "San Ramón",
    "San Vito",
    "Santa Ana",
    "Santa Bárbara",
    "Santa Cruz",
    "Sarapiquí",
    "Sarchí",
    "Siquirres",
    "Turrubares",
    "Turrialba",
    "Upala",
    "Valverde Vega",
    "Vásquez de Coronado",
    "Zarcero"
];

const DISTRICTS = [
    "Seleccione un Distrito",
   "Acapulco",
    "Acosta",
    "Aguas Zarcas",
    "Aguabuena",
    "Alajuela",
    "Alajuelita",
    "Alfaro",
    "Alvarado",
    "Ángeles",
    "Anselmo Llorente",
    "Arenal",
    "Arancibia",
    "Aserrí",
    "Bagaces",
    "Bahía Ballena",
    "Barbacoas",
    "Barra",
    "Barva",
    "Barranca",
    "Barrantes",
    "Barú",
    "Bebedero",
    "Bejuco",
    "Belén",
    "Belén de Nosarita",
    "Bijagua",
    "Bolsón",
    "Bolívar",
    "Boruca",
    "Brasil",
    "Bratsi",
    "Buenavista",
    "Buenos Aires",
    "Cabo Velas",
    "Cachí",
    "Cajón",
    "Calle Blancos",
    "Candelarita",
    "Candelaria",
    "Cangrejal",
    "Canalete",
    "Cañas",
    "Cañas Dulces",
    "Cariari",
    "Carrandi",
    "Carrillo",
    "Carrillos",
    "Cartagena",
    "Cartago",
    "Cascajal",
    "Carmen",
    "Carmona",
    "Carara",
    "Cerro Azul",
    "Cerro Cortés",
    "Chacarita",
    "Chánguena",
    "Chiles",
    "Chires",
    "Cipreses",
    "Cirrí Norte",
    "Cirrí Sur",
    "Cinco Esquinas",
    "Cóbano",
    "Colinas",
    "Colón",
    "Colorado",
    "Concepción",
    "Corralillo",
    "Corredor",
    "Coto Brus",
    "Cot",
    "Cozumal",
    "Cureña",
    "Curridabat",
    "Curubandé",
    "Cutris",
    "Daniel Flores",
    "Damas",
    "Desamparados",
    "Desamparaditos",
    "Desmonte",
    "Diriá",
    "Dos Ríos",
    "Dota",
    "Dulce Nombre",
    "Dulce Nombre de Jesús",
    "El Amparo",
    "El General",
    "El Guarco",
    "El Jardín",
    "El Monte",
    "El Rosario",
    "El Tejar",
    "Escazú",
    "Escobal",
    "Esparza",
    "Espíritu Santo",
    "Esquipulas",
    "Filadelfia",
    "Florencia",
    "Florida",
    "Frailes",
    "Garabito",
    "Garita",
    "Goicoechea",
    "Golfito",
    "Gravilias",
    "Granadilla",
    "Greacia",
    "Grifo Alto",
    "Guácima",
    "Guácimo",
    "Guadalupe",
    "Guaitil",
    "Guaitil Norte",
    "Guaitil Sur",
    "Guayabo",
    "Guaycará",
    "Guaycará Sur",
    "Guillermina",
    "Guime",
    "Hacienda Vieja",
    "Hatillo",
    "Heredia",
    "Hospital",
    "Hojancha",
    "Ipís",
    "Isla del Coco",
    "Jacó",
    "Jaris",
    "Jiménez",
    "Juan Viñas",
    "Lepanto",
    "Legua",
    "Liberia",
    "Llano Bonito",
    "Llorente",
    "Los Chiles",
    "Los Guido",
    "Limón",
    "Limóncito",
    "Macacona",
    "Mansión",
    "Mata de Plátano",
    "Mata Redonda",
    "Matama",
    "Matina",
    "Matambú",
    "Matapalo",
    "Mayorga",
    "Mercedes",
    "Mercedes Sur",
    "Merced",
    "Miramar",
    "Mogote",
    "Montes de Oro",
    "Monterrey",
    "Monte Verde",
    "Monte Verde Norte",
    "Monte Verde Sur",
    "Moravia",
    "Mora",
    "Nacascolo",
    "Nandayure",
    "Naranjito",
    "Naranjo",
    "Nosara",
    "Occidental",
    "Oreamuno",
    "Orotina",
    "Oriental",
    "Pacuare",
    "Pacuare Sur",
    "Pacuarito",
    "Pacayas",
    "Palmichal",
    "Palmares",
    "Palmar",
    "Palmira",
    "Palo Verde",
    "Paracito",
    "Paraíso",
    "Pará",
    "Paraíso Norte",
    "Paraíso Sur",
    "Parrita",
    "Patarrá",
    "Patio de Agua",
    "Pavón",
    "Pejibaye",
    "Peñas Blancas",
    "Picagres",
    "Piedras Blancas",
    "Piedras Negras",
    "Pilar",
    "Pilas",
    "Pitahaya",
    "Pital",
    "Pittier",
    "Pizarro",
    "Pocosol",
    "Poas",
    "Pocora",
    "Pozo Azul",
    "Pozos",
    "Potrero Cerrado",
    "Potrero Grande",
    "Puerto Carrillo",
    "Puerto Cortés",
    "Puerto Jiménez",
    "Puerto Viejo",
    "Puntarenas",
    "Puriscal",
    "Purral",
    "Quitirrisí",
    "Quesada",
    "Quebrada Grande",
    "Quepos",
    "Rivas",
    "Río Azul",
    "Río Blanco",
    "Río Cuarto",
    "Río Jiménez",
    "Río Naranjo",
    "Río Nuevo",
    "Río Seco",
    "Rivas",
    "Roble",
    "Rosario",
    "Roxana",
    "Sabana Redonda",
    "Sabanillas",
    "Sabalito",
    "Salitral",
    "Salitrillos",
    "San Andrés",
    "San Antonio",
    "San Carlos",
    "San Cristóbal",
    "San Felipe",
    "San Francisco",
    "San Gabriel",
    "San Ignacio",
    "San Isidro",
    "San Isidro de El General",
    "San Jerónimo",
    "San Joaquín",
    "San Jorge",
    "San José",
    "San José (Pizote)",
    "San Josecito",
    "San Juan",
    "San Juan de Dios",
    "San Juan de Mata",
    "San Juan Grande",
    "San Lorenzo",
    "San Luis",
    "San Marcos",
    "San Mateo",
    "San Miguel",
    "San Pablo",
    "San Pedro",
    "San Rafael",
    "San Rafael Abajo",
    "San Rafael Arriba",
    "San Ramón",
    "San Roque",
    "San Sebastián",
    "San Vito",
    "San Vicente",
    "Santa Ana",
    "Santa Bárbara",
    "Santa Cecilia",
    "Santa Cruz",
    "Santa Eulalia",
    "Santa Elena",
    "Santa Isabel",
    "Santa Lucía",
    "Santa María",
    "Santa Rita",
    "Santa Rosa",
    "Santa Teresita",
    "Sánchez",
    "Santiago",
    "Sarchí",
    "Sarchí Norte",
    "Sarchí Sur",
    "Savegre",
    "Sarapiquí",
    "Sierpe",
    "Siquirres",
    "Sixaola",
    "Tabor",
    "Tacar",
    "Tacares",
    "Tajuricco",
    "Talamanca",
    "Tambor",
    "Tapatal",
    "Tarbaca",
    "Tarrazú",
    "Tayutic",
    "Tibás",
    "Tilarán",
    "Tirrases",
    "Tierras Morenas",
    "Tobosi",
    "Tres Equis",
    "Trinidad",
    "Tronadora",
    "Tuis",
    "Turrialba",
    "Turrubares",
    "Turrúcares",
    "Upala",
    "Uruca",
    "Ulloa",
    "Valle La Estrella",
    "Vara Blanca",
    "Venado",
    "Venecia",
    "Vuelta de Jorco",
    "Yolillal",
    "Zapotal",
    "Zapote",
    "Zaragoza",
    "Zarcero"
];

export {
    COUNTRIES,
    CATEGORIES,
    SECTORS,
    LATIN_COUNTRIES,
    DEPARTMENTS,
    MUNICIPALITIES,
    PROVINCES,
    CANTONS,
    DISTRICTS,
    ALL_DEPARTMENTS,
    ALL_MUNICIPALITIES,
    EXCLUSIVE_LATIN_COUNTRIES,
    CATEGORIESS
        
      
};
